/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, CircularProgress } from "@mui/material";
import { Fragment, useRef } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchDocumentRevisionUrlRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionUrlRule";
import { IconDownloadCloud } from "../../../commons/icons";

interface DownloadRevisionProps {
  documentId: string;
  collaborationId: string;
  revisionId: string;
}

export const DownloadRevisionButton = ({ collaborationId, documentId, revisionId }: DownloadRevisionProps) => {
  const { fetchDocumentTurnRevisionDownloadUrl, loadingDocumentRevisionDownloadUrl } =
    useFetchDocumentRevisionUrlRule();
  const { t } = useSharedCollaborationTranslation();

  const hiddenDownloadLinkRef = useRef(null);

  const fetchDocumentUrlAndStartDownload = async () => {
    hiddenDownloadLinkRef.current.href = await fetchDocumentTurnRevisionDownloadUrl(
      collaborationId,
      documentId,
      revisionId,
    );
    hiddenDownloadLinkRef.current.click();
  };

  return (
    <Fragment>
      <a ref={hiddenDownloadLinkRef} style={{ display: "none" }}></a>
      <Button
        variant={"text"}
        onClick={fetchDocumentUrlAndStartDownload}
        disabled={loadingDocumentRevisionDownloadUrl}
        startIcon={
          loadingDocumentRevisionDownloadUrl ? (
            <CircularProgress color={"secondary"} size={20} variant={"indeterminate"} />
          ) : (
            <IconDownloadCloud />
          )
        }
        sx={{ fontSize: { xs: 14, sm: 16 } }}
      >
        {loadingDocumentRevisionDownloadUrl
          ? t("DownloadTurnRevisionButton.loading")
          : t("DownloadTurnRevisionButton.download")}
      </Button>
    </Fragment>
  );
};
