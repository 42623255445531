/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, SxProps } from "@mui/material";
import { forwardRef } from "react";
import { Request } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { RequestIconMarker, RequestUpdateMarker, RequestWordMarker } from "@taketurns-components/collaboration/Shared/content/Markers";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { ListItemTitle } from "../ListItemTitle";
import { RequestUploadChip } from "./RequestUploadChip";

interface DesktopRequestListItemProps {
  request: Request;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayRequestMarker: boolean;
  shouldDisplayUploadChip: boolean;
  shouldDisplayUpdateMarker: boolean;
  shouldStrikeTitle: boolean;
  openRequestFulfillment: () => void;
  canConnectedUserFulFillRequest: boolean;
  sx?: SxProps;
}

export const DesktopRequestListItem = forwardRef<HTMLLIElement | null, DesktopRequestListItemProps>((props, ref) => {
  const { color: lastUpdateColor } = useFetchLastUpdateColor();

  return (
    <TakeTurnsListItem
      sx={{
        borderRight: `8px solid ${props.shouldDisplayLastUpdate ? lastUpdateColor : undefined}`,
        "&:hover": {
          borderRight: `8px solid ${props.shouldDisplayLastUpdate ? lastUpdateColor : undefined}`,
        },
        ...props.sx,
      }}
      ref={ref}
      onClick={props.canConnectedUserFulFillRequest ? props.openRequestFulfillment : undefined}
      titleSlot={
        <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
          <ListItemTitle title={props.request.name} shouldStrikeTitle={props.shouldStrikeTitle} />
          {props.shouldDisplayRequestMarker && <RequestIconMarker />}
          {props.shouldDisplayUpdateMarker && <RequestUpdateMarker request={props.request} />}
          {props.shouldDisplayUploadChip && <RequestUploadChip />}
        </Stack>
      }
      middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.request.prevStatus} />}
      rightEndSlot={<RequestWordMarker />}
    />
  );
});
DesktopRequestListItem.displayName = "DesktopRequestListItem";
