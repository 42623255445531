/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ButtonProps, CircularProgress, Tooltip } from "@mui/material";
import { Fragment, useRef } from "react";
import { TakeTurnsGreyButton } from "@taketurns-components/commons/button";
import { IconUploadCloud } from "@taketurns-components/commons/icons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useUploadAttachmentRevisionRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useUploadAttachmentRevisionRule";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { useFetchAttachmentAvailableActionsRule } from "@taketurns-rules/collaboration/queries/attachment/useFetchAttachmentAvailableActionsRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";

export const AddAttachmentRevisionButton = (buttonProps: Omit<ButtonProps, "onClick" | "startIcon">) => {
  const { t } = useAttachmentDetailsTranslation();
  const attachment = useGetOpenedAttachmentRule();

  const { selectRevision, selectedRevisionId } = useSelectDocumentRevisionRule();
  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  const { isUploadRevisionDisabled } = useFetchAttachmentAvailableActionsRule(attachment);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const uploadAttachmentRevisionRule = useUploadAttachmentRevisionRule(attachment);
  const uploadAttachmentRevision = async () => {
    const file = fileInputRef.current.files[0];
    await uploadAttachmentRevisionRule(file);
    fileInputRef.current = null;
  };

  if (!isAttachmentRevisionAddInProgress && !selectedRevisionId) {
    selectRevision(attachment.revisionId);
  }

  return (
    <Fragment>
      <input
        onChange={uploadAttachmentRevision}
        ref={fileInputRef}
        type="file"
        hidden
        data-cy="AddAttachmentRevisionButton.HiddenFileInput"
      />
      <Tooltip
        title={t("cannotUploadRevisionOnLockedAttachment")}
        placement="bottom-end"
        disableFocusListener={!attachment.locked}
        disableHoverListener={!attachment.locked}
        disableTouchListener={!attachment.locked}
      >
        <span>
          <TakeTurnsGreyButton
            {...buttonProps}
            disabled={isUploadRevisionDisabled}
            onClick={() => fileInputRef.current.click()}
            startIcon={
              isAttachmentRevisionAddInProgress ? (
                <CircularProgress color={"secondary"} size={20} variant={"indeterminate"} />
              ) : (
                <IconUploadCloud />
              )
            }
            sx={{ fontSize: { xs: 14, sm: 16 } }}
          >
            {isAttachmentRevisionAddInProgress ? t("loadingRevision") : t("uploadRevisionButton")}
          </TakeTurnsGreyButton>
        </span>
      </Tooltip>
    </Fragment>
  );
};
