/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  Attachment,
  ContentStatus,
  Folder,
  MutationRemoveAttachmentArgs,
  RemoveAttachmentOutput,
} from "@taketurns/taketurns-graphql-repository";
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useGetContentMetadataInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetContentMetadataInCache";
import { useGetFolderInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetFolderInCache";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useGetCurrentFolderIdInCollaborationView } from "../../../../state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { attachmentFragment } from "../../../fragments/attachmentFragment";
import { requestFragment } from "../../../fragments/requestFragment";

export const removeAttachmentMutationFragment = gql`
  fragment RemoveAttachmentMutationFragment on RemoveAttachmentOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      attachments {
        ...AttachmentFragment
      }
      requests {
        ...RequestFragment
      }
      attachmentCount
      attachmentToReviewCount
      requestCount
      folderCount
      updateCount
    }
    contentMetadata {
      id
      hasPendingUpdates
      todoCount
      requestToFulfillCount
      hasNoContent
    }
  }
  ${requestFragment}
  ${attachmentFragment}
`;

export const useRemoveAttachment = (collaborationId: string, attachment: Attachment) => {
  const folderId = useGetCurrentFolderIdInCollaborationView();
  const getFolderInCache = useGetFolderInCache();
  const { moveBackward } = useBrowseContentRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const getContentMetadataInCache = useGetContentMetadataInCache();

  const REMOVE_ATTACHMENT = gql`
    ${removeAttachmentMutationFragment}
    ${attachmentFragment}
    mutation removeAttachment($input: RemoveAttachmentInput!) {
      removeAttachment(input: $input) {
        ...RemoveAttachmentMutationFragment
      }
    }
  `;

  const [removeAttachmentMutationFromGraphQL, { data, loading, error }] = useMutation<
    { removeAttachment: RemoveAttachmentOutput },
    MutationRemoveAttachmentArgs
  >(REMOVE_ATTACHMENT);
  const removeAttachmentMutation = () => {
    const contentMetadata = getContentMetadataInCache(collaborationId);
    if (attachment.toReview) {
      contentMetadata.attachmentToReviewCount = contentMetadata.attachmentToReviewCount - 1;
      contentMetadata.todoCount = contentMetadata.todoCount - 1;
    }
    return removeAttachmentMutationFromGraphQL({
      variables: { input: { collaborationId, attachmentId: attachment.id } },
      optimisticResponse: {
        removeAttachment: {
          collaborationId,
          partyId: connectedUserPartyId,
          folderId,
          folder: getFolderAfterRemoval(),
          contentMetadata,
        },
      },
      onCompleted: (data) => {
        if (data.removeAttachment.folder.id !== folderId) {
          moveBackward();
        }
      },
    });
  };
  return {
    removeAttachmentMutation,
    removeAttachmentResponseData: data?.removeAttachment,
    removeAttachmentLoading: loading,
    errorOnRemovingAttachment: error,
  };

  function getFolderAfterRemoval() {
    const folder = structuredClone(getFolderInCache(folderId));
    if (attachment.status === ContentStatus.Added) {
      folder.attachments = folder.attachments.filter((a) => a.id !== attachment.id);
    } else {
      const removedAttachmentIndex = folder.attachments.findIndex(
        (existingAttachment) => existingAttachment.id === attachment.id,
      );
      if (removedAttachmentIndex !== -1) {
        folder.attachments[removedAttachmentIndex].status = ContentStatus.Removed;
      }
    }

    return {
      ...folder,
      attachmentCount: folder.attachmentCount ?? -1,
      attachmentToReviewCount: folder.attachmentToReviewCount ?? -1,
      requestCount: folder.requestCount ?? -1,
      folderCount: folder.folderCount ?? -1,
      updateCount: folder.updateCount ?? -1,
    } as Folder;
  }
};
