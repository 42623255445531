/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { createContext } from "react";

export interface AuthContextType {
  isAuthenticated: boolean;
  isUserFirstVisit: boolean;
  login: (username: string, password: string) => Promise<void>;
  loginLoading: boolean;
  loginError: { code: string; message: string } | null;
  logout: () => Promise<void>;
  getAccessToken: () => Promise<string>;
  passwordLessProcess: {
    loginWithAuthenticationCode: (authenticationCode: string) => Promise<void>;
    createAccountOrUseExistingAndSendAuthenticationCode: (email: string) => Promise<void>;
    authenticationCodeSent: boolean;
    resendAuthenticationCode: (email: string) => Promise<void>;
    error: unknown | null;
    loading: boolean;
  };
}

export const AuthContext = createContext<AuthContextType | null>(null);
AuthContext.displayName = "AuthContext";
