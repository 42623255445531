/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Divider, Stack, styled } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { DocumentRevisionPreview } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionPreview";
import {
  MobileAttachmentDetailsActionsBar,
} from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsActionsBar";
import {
  MobileAttachmentDetailsHeader,
} from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsHeader";
import { ConfirmBeforeClosingDialog } from "@taketurns-components/document/FilePreview/PdfViewer/ConfirmBeforeClosingDialog";
import { PdfViewerExposedFunction } from "@taketurns-components/document/FilePreview/PdfViewer/PdfViewer";
import { useRemoveAttachmentRevisionRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useRemoveAttachmentRevisionRule";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { useConfirmCloseDetailsOnAnnotationUnsaved } from "@taketurns-rules/collaboration/commands/collaborationView/useConfirmCloseDetailsOnAnnotationUnsavedRule";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { useFetchAttachmentAvailableActionsRule } from "@taketurns-rules/collaboration/queries/attachment/useFetchAttachmentAvailableActionsRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { useIsAttachmentDetailsOpenRule } from "@taketurns-rules/collaboration/queries/attachment/useIsAttachmentDetailsOpenRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { MobileFullPageSheet } from "../../../../../commons/MobileSheets/MobileFullPageSheet";
import { MobileAttachmentDetailsPageHeader } from "./MobileAttachmentDetailsPageHeader";

export const MobileAttachmentDetails = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const attachment = useGetOpenedAttachmentRule();

  const isAttachmentDetailsOpen = useIsAttachmentDetailsOpenRule();

  const { loadingRemoveRevision } = useRemoveAttachmentRevisionRule();

  const { canEditDocumentDetails, canDelete, canUploadRevision, canMarkForReview, isDeleteAttachmentDisabled } =
    useFetchAttachmentAvailableActionsRule(attachment);

  const { selectRevision, selectedRevisionId } = useSelectDocumentRevisionRule();

  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  useEffect(() => {
    if (!isAttachmentRevisionAddInProgress && !selectedRevisionId) {
      selectRevision(attachment.revisionId);
    }
  }, [attachment.revisionId, isAttachmentRevisionAddInProgress, selectRevision, selectedRevisionId]);

  const pdfViewerComponentRef = useRef<PdfViewerExposedFunction>(null);

  const saveRevision = async () => {
    await pdfViewerComponentRef.current.uploadPdfRevision();
  };

  const cancelAnnotations = () => {
    pdfViewerComponentRef.current.cancelPendingAnnotations();
  };

  const {
    isPdfUpdated,
    setIsPdfUpdated,
    isConfirmBeforeClosingDialogOpen,
    confirmBeforeClose,
    closeConfirmBeforeClosingDialog,
    confirmClosingDialogs,
  } = useConfirmCloseDetailsOnAnnotationUnsaved();

  const openAiAssistant = () => {
    console.log("openAiAssistant");
  };

  if (!selectedRevisionId) {
    return null;
  }

  if (!isAttachmentDetailsOpen) {
    return;
  }

  return (
    <MobileFullPageSheet
      isOpen={isAttachmentDetailsOpen}
      header={<MobileAttachmentDetailsPageHeader closeDialog={confirmBeforeClose} openAiAssistant={openAiAssistant} />}
    >
      <MobileAttachmentDetails__Container className="MobileAttachmentDetailsPage">
        <MobileAttachmentDetailsHeader
          attachment={attachment}
          selectedRevisionId={selectedRevisionId}
          canUploadRevision={canUploadRevision}
          canEditDocumentDetails={canEditDocumentDetails}
        />
        <Divider />
        <DocumentPreview__Container className="DocumentPreview__Container">
          <DocumentRevisionPreview
            ref={pdfViewerComponentRef}
            collaborationId={collaborationId}
            documentId={attachment.id}
            revisionId={selectedRevisionId}
            loadingRemoveRevision={loadingRemoveRevision}
            canEditRevision={canEditDocumentDetails}
            onPdfUpdatedChange={setIsPdfUpdated}
          />
        </DocumentPreview__Container>
        <Divider />
        <MobileAttachmentDetailsActionsBar
          canMarkForReview={canMarkForReview}
          canDelete={canDelete}
          isDeleteAttachmentDisabled={isDeleteAttachmentDisabled}
          attachment={attachment}
          collaborationId={collaborationId}
          selectedRevisionId={selectedRevisionId}
          isPdfUpdated={isPdfUpdated}
          onSaveRevisionButtonClick={saveRevision}
          onCancelAnnotationsButtonClick={cancelAnnotations}
        />
        <ConfirmBeforeClosingDialog
          isConfirmBeforeClosingDialogOpen={isConfirmBeforeClosingDialogOpen}
          onConfirm={confirmClosingDialogs}
          onCancel={closeConfirmBeforeClosingDialog}
        />
      </MobileAttachmentDetails__Container>
    </MobileFullPageSheet>
  );
};
const MobileAttachmentDetails__Container = styled(Stack)({
  flex: 1,
});

const DocumentPreview__Container = styled(Box)({
  flex: 1,
});
