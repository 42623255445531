/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, CircularProgress } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import {
  useCollaborationViewTranslation,
} from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useDownloadContentRule } from "@taketurns-rules/document/commands/useDownloadContentRule";
import { IconDownloadCloud } from "../../../../commons/icons";

export const DownloadTurnContent = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { downloadContent, turnContentDownloadUrl, loading } = useDownloadContentRule();
  const { t } = useCollaborationViewTranslation();
  const hiddenDownloadLinkRef = useRef<HTMLAnchorElement>(null);
  const isOnMobile = useIsOnMobileRule();

  const downloadTranslationKey = isOnMobile ? "downloadAll" : "downloadAllFiles";

  const startDownloadTurnContent = async () => {
    return downloadContent(collaborationId);
  };

  useEffect(() => {
    if (turnContentDownloadUrl) {
      if (hiddenDownloadLinkRef.current) {
        hiddenDownloadLinkRef.current!.href = turnContentDownloadUrl;
        hiddenDownloadLinkRef.current!.click();
      }
    }
  }, [turnContentDownloadUrl, hiddenDownloadLinkRef]);

  return (
    <Fragment>
      <Button
        variant={"text"}
        disabled={loading}
        disableRipple
        onClick={startDownloadTurnContent}
        startIcon={loading ? <CircularProgress size={24} /> : <IconDownloadCloud />}
      >
        {t(`DownloadTurnContent.${loading ? "loading" : downloadTranslationKey}`)}
      </Button>
      <a ref={hiddenDownloadLinkRef} style={{ display: "none" }}></a>
    </Fragment>
  );
};
