/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { CardTitle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/components/CardTitle";
import { ExpectedActionCardHeader } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/ExpectedActionCardHeader";
import { TakeTurnsCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/TakeTurnsCard";
import { TakeTurnsCardContent } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/TakeTurnsCardContent";
import { TakeTurnsCardFooter } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/TakeTurnsCardFooter";
import { ContentElementLastUpdateInCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdateInCard";
import { RequestWordMarker } from "@taketurns-components/collaboration/Shared/content/Markers";
import { FilesDragAndDropWithOverlay, FilesDragAndDropWithOverlayConfig } from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { IconRequest } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useOpenRequestFulfillmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentRule";
import { useOpenRequestFulfillmentWithFilesRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentWithFilesRule";
import { useGetRequestRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetRequestRule";
import { useCanConnectedUserFulFillRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserFulFillRequestRule";
import { useShouldDisplayUpdateMarkerForRequestRule } from "@taketurns-rules/collaboration/queries/request/useShouldDisplayUpdateMarkerForRequestRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { ContentStatus as ContentStatusEnum, Request } from "@taketurns/taketurns-graphql-repository";
import { Fragment } from "react";

interface RequestCardProps {
  requestId: string;
  editable: boolean;
}

export const RequestFulfillmentCard = ({ requestId, editable }: RequestCardProps) => {
  const request = useGetRequestRule(requestId);
  const folderId = useGetCurrentFolderIdInCollaborationView();
  const requestFulfillmentInput = {
    folderId,
    requestName: request.name,
    requestDescription: request.description as string,
  };
  const openRequestFulfillment = useOpenRequestFulfillmentRule(requestFulfillmentInput);
  const openRequestFulfillmentWithFiles = useOpenRequestFulfillmentWithFilesRule(requestFulfillmentInput);
  const shouldDisplayUpdateMarker = useShouldDisplayUpdateMarkerForRequestRule(request);

  const canConnectedUserFulFillRequest = useCanConnectedUserFulFillRequestRule();
  if (editable && canConnectedUserFulFillRequest(request)) {
    const dropZoneConfig: FilesDragAndDropWithOverlayConfig = {
      handleFilesDropping: openRequestFulfillmentWithFiles,
      multiple: true,
      className: "RequestCard__DropZone",
      sx: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
    };
    return (
      <TakeTurnsCard
        onClick={openRequestFulfillment}
        bgcolor={shouldDisplayUpdateMarker ? TakeTurnsColors.updatedElementBackground : undefined}
      >
        <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig} roundedDropZone>
          <CardContent request={request} />
        </FilesDragAndDropWithOverlay>
      </TakeTurnsCard>
    );
  }

  return (
    <TakeTurnsCard>
      <CardContent request={request} />
    </TakeTurnsCard>
  );
};
RequestFulfillmentCard.displayName = "InnerRequestCard";

function CardContent({ request }: { request: Request }) {
  const shouldDisplayLastUpdatesMarker = request.prevStatus && request.prevStatus !== ContentStatusEnum.Unchanged;
  return (
    <Fragment>
      <RequestFulfillmentCardHeader />
      <TakeTurnsCardContent sx={{ paddingTop: "7px" }}>
        <CardTitle title={request.name} />
      </TakeTurnsCardContent>
      {shouldDisplayLastUpdatesMarker && <ContentElementLastUpdateInCard status={request.prevStatus} />}
      <TakeTurnsCardRequestFooter>
        <RequestWordMarker />
      </TakeTurnsCardRequestFooter>
    </Fragment>
  );
}

const RequestFulfillmentCardHeader = () => {
  const { t } = useSharedCollaborationTranslation();
  return <ExpectedActionCardHeader actionLabel={t("RequestCard.action")} ActionIcon={IconRequest} />;
};

const TakeTurnsCardRequestFooter = styled(TakeTurnsCardFooter)({
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 5px 0 10px",
});
