/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SxProps } from "@mui/material";
import { forwardRef, Fragment } from "react";
import { Request } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { RequestIconMarker, RequestUpdateMarker, RequestWordMarker } from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { ListItemTitle } from "../ListItemTitle";
import { RequestUploadChip } from "./RequestUploadChip";

interface MobileRequestListItemProps {
  request: Request;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayRequestMarker: boolean;
  shouldDisplayUploadChip: boolean;
  shouldDisplayUpdateMarker: boolean;
  shouldStrikeTitle: boolean;
  openRequestFulfillment: () => void;
  canConnectedUserFulFillRequest: boolean;
  sx?: SxProps;
}

export const MobileRequestListItem = forwardRef<HTMLLIElement | null, MobileRequestListItemProps>(
  (props: MobileRequestListItemProps, ref) => (
    <MobileListItem
      sx={props.sx}
      ref={ref}
      titleSlot={<ListItemTitle title={props.request.name} shouldStrikeTitle={props.shouldStrikeTitle} />}
      subtitleSlot={
        <Fragment>
          <RequestWordMarker />
          {props.shouldDisplayRequestMarker && <RequestIconMarker />}
          {props.shouldDisplayUpdateMarker && <RequestUpdateMarker request={props.request} />}
          {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.request.prevStatus} />}
        </Fragment>
      }
      rightEndSlot={props.shouldDisplayUploadChip && <RequestUploadChip />}
      onClick={props.canConnectedUserFulFillRequest ? props.openRequestFulfillment : undefined}
    />
  ),
);

MobileRequestListItem.displayName = "MobileRequestListItem";
