/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { forwardRef } from "react";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { FolderIcon } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderIcon";
import { FolderStats } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderStats";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { FolderUpdatesMarker, ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { ListItemTitle } from "../ListItemTitle";

interface DesktopFolderListItemProps {
  folder: Folder;
  isFolderRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  lastUpdateStatus: ContentStatus;
  shouldDisplayUpdatesMarker: boolean;
  shouldDisplayReviewChip: boolean;
  hasFolderBeenRemovedInPreviousTurn: boolean;
  moveIntoListItemFolder: () => void;
  backgroundColor: string;
}

export const DesktopFolderListItem = forwardRef<HTMLLIElement | null, DesktopFolderListItemProps>((props, ref) => {
  const { color: lastUpdateColor } = useFetchLastUpdateColor();
  return (
    <TakeTurnsListItem
      ref={ref}
      titleSlot={
        <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
          <FolderIcon />
          <ListItemTitle title={props.folder.name} shouldStrikeTitle={props.isFolderRemoved} />
          {props.shouldDisplayUpdatesMarker && <FolderUpdatesMarker folder={props.folder} />}
        </Stack>
      }
      subtitleSlot={props.shouldDisplayReviewChip && <ReviewListItemChip />}
      middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.lastUpdateStatus} />}
      rightEndSlot={<FolderStats folder={props.folder} />}
      onClick={props.hasFolderBeenRemovedInPreviousTurn ? undefined : props.moveIntoListItemFolder}
      sx={{
        borderRight: `8px solid ${props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor}`,
        backgroundColor: props.backgroundColor,
      }}
    />
  );
});

DesktopFolderListItem.displayName = "DesktopFolderListItem";
