/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useFragment } from "@apollo/client";

export const subFolderFragment = gql`
  fragment SubFolderFragment on Folder {
    id
    name
    status @nonreactive
    prevStatus @nonreactive
    attachmentCount @nonreactive
    attachments {
      id
    }
    requestCount @nonreactive
    folderCount @nonreactive
    updateCount @nonreactive
    prevUpdateCount @nonreactive
    requestToFulfillCount @nonreactive
    attachmentToReviewCount @nonreactive
  }
`;

export const useGetSubFolderFragment = (folderId: string) => {
  return useFragment({
    fragment: subFolderFragment,
    from: { id: folderId, __typename: "Folder" },
  });
};
