/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Dialog, DialogContent, DialogTitle, Divider, styled, SxProps, Theme } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { Fragment, ReactNode } from "react";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface ResponsiveDialogProps extends Omit<DialogProps, "title" | "content"> {
  title?: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
  contentContainerStyle?: SxProps<Theme>;
}

export const ResponsiveDialog = ({
  title,
  content,
  footer,
  contentContainerStyle,
  ...props
}: ResponsiveDialogProps) => {
  const isOnMobile = useIsOnMobileRule();

  return (
    <ResponsiveDialog__Dialog fullScreen={isOnMobile} {...props}>
      {title && (
        <Fragment>
          <ResponsiveDialog__Title>{title}</ResponsiveDialog__Title>
          <Divider />
        </Fragment>
      )}
      <DialogContent sx={contentContainerStyle}>{content}</DialogContent>
      {footer}
    </ResponsiveDialog__Dialog>
  );
};

const ResponsiveDialog__Dialog = styled(Dialog)(({ theme, fullScreen }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("md")]: {
      borderRadius: fullScreen ? 0 : "",
    },

    "& .MuiDialogActions-root": {
      paddingBottom: "calc(24px + env(safe-area-inset-bottom))",
    },
  },
}));

const ResponsiveDialog__Title = styled(DialogTitle)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
