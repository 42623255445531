/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { forwardRef, Fragment } from "react";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { FolderIcon } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderIcon";
import { FolderStats } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderStats";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { FolderUpdatesMarker, ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { ListItemTitle } from "../ListItemTitle";

interface MobileFolderListItemProps {
  folder: Folder;
  isFolderRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayUpdatesMarker: boolean;
  lastUpdateStatus: ContentStatus;
  shouldDisplayReviewChip: boolean;
  hasFolderBeenRemovedInPreviousTurn: boolean;
  moveIntoListItemFolder: () => void;
  backgroundColor: string;
}

export const MobileFolderListItem = forwardRef<HTMLLIElement | null, MobileFolderListItemProps>((props, ref) => (
  <MobileListItem
    sx={{ backgroundColor: props.backgroundColor }}
    ref={ref}
    titleSlot={
      <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
        <FolderIcon />
        <ListItemTitle title={props.folder.name} shouldStrikeTitle={props.isFolderRemoved} />
      </Stack>
    }
    subtitleSlot={
      <Fragment>
        <FolderStats folder={props.folder} />
        {props.shouldDisplayUpdatesMarker && <FolderUpdatesMarker folder={props.folder} />}
        {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.lastUpdateStatus} />}
      </Fragment>
    }
    rightEndSlot={props.shouldDisplayReviewChip && <ReviewListItemChip />}
    onClick={props.hasFolderBeenRemovedInPreviousTurn ? undefined : props.moveIntoListItemFolder}
  />
));

MobileFolderListItem.displayName = "MobileFolderListItem";
