/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, Fragment } from "react";
import { ContentStatus as ContentStatusEnum, Request } from "@taketurns/taketurns-graphql-repository";
import {
  RequestMoveDestinationDialogInCollaborationView,
} from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/RequestMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { EditRequestDialog } from "@taketurns-components/collaboration/Shared/content/EditRequestDialog";
import { FilesDragAndDropWithOverlay, FilesDragAndDropWithOverlayConfig } from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useModifyRequestDescriptionRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useModifyRequestDescriptionRule";
import { useOpenRequestFulfillmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentRule";
import { useOpenRequestFulfillmentWithFilesRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentWithFilesRule";
import { useRenameRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useRenameRequestRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetRequestRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetRequestRule";
import { useIsRequestMadeByConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useIsRequestMadeByConnectedUserPartyRule";
import { useCanConnectedUserEditRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserEditRequestRule";
import { useCanConnectedUserFulFillRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserFulFillRequestRule";
import { useGetRequestContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/request/useGetRequestContextMenuConfigurationRule";
import { useShouldDisplayUpdateMarkerForRequestRule } from "@taketurns-rules/collaboration/queries/request/useShouldDisplayUpdateMarkerForRequestRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToEditRequestRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToEditRequestRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { DesktopRequestListItem } from "./DesktopRequestListItem";
import { MobileRequestListItem } from "./MobileRequestListItem";

interface RequestListItemProps {
  requestId: string;
  editable: boolean;
}

export const RequestListItem = ({ requestId, editable }: RequestListItemProps) => {
  const request = useGetRequestRule(requestId);
  useSubscribeToEditRequestRule(requestId);
  const { modifyRequestDescription } = useModifyRequestDescriptionRule(request.id);
  const canConnectedUserEditRequest = useCanConnectedUserEditRequestRule();

  const { renameRequest } = useRenameRequestRule(request.id);
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();
  const requestContextMenuConfiguration = useGetRequestContextMenuConfigurationRule(
    request.id,
    openEditDialog,
    openMoveDestinationDialog,
  );

  let listItem = <ListItem requestId={requestId} editable={editable} />;
  if (!hasRequestBeenRemoved(request) && editable && canConnectedUserEditRequest(request)) {
    const ListItemWithContextMenu = WithContextMenu<RequestListItemProps>(ListItem, requestContextMenuConfiguration);
    listItem = (
      <Fragment>
        <ListItemWithContextMenu requestId={requestId} editable={editable} />
        <EditRequestDialog
          open={isEditDialogOpened}
          onClose={closeEditDialog}
          requestName={request.name}
          requestDescription={request.description as string}
          modifyRequestDescription={modifyRequestDescription}
          changeRequestName={renameRequest}
        />
        <RequestMoveDestinationDialogInCollaborationView
          requestId={request.id}
          open={isMoveDestinationDialogOpen}
          onClose={closeMoveDestinationDialog}
        />
      </Fragment>
    );
  }

  return listItem;
};

const hasRequestBeenRemoved = (request: Request) =>
  request.status === ContentStatusEnum.Removed || request.prevStatus === ContentStatusEnum.Removed;

const ListItem = forwardRef(
  ({ requestId, editable }: RequestListItemProps, ref: ForwardedRef<HTMLLIElement | null>) => {
    const request = useGetRequestRule(requestId);
    const isOnMobile = useIsOnMobileRule();

    const folderId = useGetCurrentFolderIdInCollaborationView();
    const requestFulfillmentInput = {
      folderId,
      requestName: request.name,
      requestDescription: request.description as string,
    };
    const openRequestFulfillment = useOpenRequestFulfillmentRule(requestFulfillmentInput);
    const openRequestFulfillmentWithFiles = useOpenRequestFulfillmentWithFilesRule(requestFulfillmentInput);

    const canConnectedUserFulFillRequest = useCanConnectedUserFulFillRequestRule();
    const shouldDisplayLastUpdatesMarker = request.prevStatus && request.prevStatus !== ContentStatusEnum.Unchanged;
    const shouldDisplayRequestMarker = useIsRequestMadeByConnectedUserPartyRule()(request);
    const shouldDisplayUploadChip = !shouldDisplayRequestMarker;
    const shouldDisplayUpdateMarker = useShouldDisplayUpdateMarkerForRequestRule(request);
    const shouldStrikeTitle = hasRequestBeenRemoved(request);

    const ItemComponent = isOnMobile ? MobileRequestListItem : DesktopRequestListItem;
    const item = (
      <ItemComponent
        sx={{ backgroundColor: shouldDisplayUpdateMarker ? TakeTurnsColors.updatedElementBackground : undefined }}
        request={request}
        openRequestFulfillment={openRequestFulfillment}
        canConnectedUserFulFillRequest={editable && canConnectedUserFulFillRequest(request)}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        shouldDisplayRequestMarker={shouldDisplayRequestMarker}
        shouldDisplayUploadChip={shouldDisplayUploadChip}
        shouldDisplayUpdateMarker={shouldDisplayUpdateMarker}
        shouldStrikeTitle={shouldStrikeTitle}
        ref={ref}
      />
    );

    if (editable && canConnectedUserFulFillRequest(request)) {
      const dropZoneConfig: FilesDragAndDropWithOverlayConfig = {
        handleFilesDropping: openRequestFulfillmentWithFiles,
        multiple: true,
        className: "RequestCard__DropZone",
        sx: {
          flex: 1,
          display: "flex",
          flexDirection: "column",
        },
      };

      return (
        <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
          {item}
        </FilesDragAndDropWithOverlay>
      );
    }
    return item;
  },
);

ListItem.displayName = "InnerRequestListItem";
