/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const TakeTurnsCard = styled(Box)((props: { onClick?: () => void }) => ({
  height: 170,
  width: 135,
  position: "relative",
  borderRadius: 10,
  flexDirection: "column",
  display: "flex",
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  "&:hover": {
    cursor: props.onClick ? "pointer" : "default",
    backgroundColor: props.onClick ? TakeTurnsColors.hoverBackground : undefined,
  },
}));
