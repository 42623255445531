/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
export const dataUrlToFile: (url: string, fileName: string, mimeType: string) => Promise<File> = async (
  url: string,
  fileName: string,
  mimeType: string,
) => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();

  return new File([buffer], fileName, { type: mimeType });
};
