/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect, useState } from "react";
import { useDownloadContent } from "@taketurns-repositories/document/graphql/mutations/useDownloadContent";
import {
  useTurnContentDownloadStatus,
} from "@taketurns-repositories/document/graphql/queries/useTurnContentDownloadStatus";

export const useDownloadContentRule = () => {
  const [loading, setLoading] = useState(false);

  const { getContentDownloadStatus, contentDownloadUrl, contentDownloadStatusError } = useTurnContentDownloadStatus();
  useEffect(() => {
    if (contentDownloadUrl || contentDownloadStatusError) {
      setLoading(false);
    }
  }, [contentDownloadStatusError, contentDownloadUrl]);

  const { downloadTurnContent, downloadTurnContentError, downloadTurnContentLoading, downloadId } =
    useDownloadContent();

  useEffect(() => {
    if (downloadTurnContentLoading) {
      setLoading(true);
    }
    if (downloadTurnContentError) {
      console.error(downloadTurnContentError);
      setLoading(false);
    }
    if (downloadId) {
      getContentDownloadStatus(downloadId);
    }
  }, [downloadTurnContentLoading, downloadTurnContentError, downloadId]);

  const downloadContent = async (collaborationId: string) => {
    return downloadTurnContent({ collaborationId });
  };

  return { downloadContent, loading, turnContentDownloadUrl: contentDownloadUrl };
};
