/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { styled } from "@mui/material";
import { FunctionComponent, useRef } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { DialogFooter } from "../../commons/dialog/DialogFooter";
import { ResponsiveDialog } from "../../commons/dialog/ResponsiveDialog";
import { HelpCenterTextButton } from "../../webapp/HelpCenterTextButton";

export interface MoveDestinationDialogProps {
  onSubmitDestination: (folderId: string) => void;
  open: boolean;
  onClose: () => void;
  excludedFolderId?: string;
  DestinationFolderListSlot: FunctionComponent<DestinationFolderListSlotProps>;
}

interface DestinationFolderListSlotProps {
  excludedFolderId?: string;
  onSelectedFolderChange: (selectedFolderId: string) => void;
}

export const MoveDestinationDialog = ({
  onSubmitDestination,
  open,
  onClose,
  excludedFolderId,
  DestinationFolderListSlot,
}: MoveDestinationDialogProps) => {
  const { t } = useSharedCollaborationTranslation();
  const selectedFolderPathRef = useRef<string>("/");

  const closeDialog = () => {
    selectedFolderPathRef.current = undefined;
    onClose();
  };

  return (
    <MoveDestination__Dialog
      open={open}
      maxWidth="md"
      fullWidth
      title={t("MoveDestinationDialog.title")}
      content={
        <DestinationFolderListSlot
          excludedFolderId={excludedFolderId}
          onSelectedFolderChange={(selectedFolderPath: string) => (selectedFolderPathRef.current = selectedFolderPath)}
        />
      }
      footer={
        <DialogFooter
          helpSection={<HelpCenterTextButton helpCenterSection={HelpCenterSections.MOVE} />}
          onClose={closeDialog}
        >
          <TakeTurnsDefaultButton onClick={() => onSubmitDestination(selectedFolderPathRef.current)}>
            {t("MoveDestinationDialog.moveButton")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};

const MoveDestination__Dialog = styled(ResponsiveDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.up("md")]: {
      height: "533px",
    },
  },
}));
