/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback, useRecoilValue } from "recoil";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByFolderIdState } from "../../../recoil/collaborationCreation/parentFolderIdByFolderIdState";
import { pathInCreationState } from "../../../recoil/collaborationCreation/pathInCreationState";

export const useAddFolderInCreation = () => {
  const path = useRecoilValue(pathInCreationState);

  return useRecoilCallback(({ set, snapshot }) => (folderName: string) => {
    const targetFolderId = path.at(-1);
    const folderId = `${targetFolderId}/${folderName.trim()}`.replace("//", "/");
    const targetFolder = snapshot.getLoadable(folderByIdState(targetFolderId)).contents;

    if (!targetFolder.folders.includes(folderId)) {
      createNewFolderInState();
      addNewFolderInParentFolderInState();
      setParentFolderIdInState();
    }

    function createNewFolderInState() {
      set(folderByIdState(folderId), {
        name: folderName,
        attachments: [],
        folders: [],
        requests: [],
      });
    }

    function addNewFolderInParentFolderInState() {
      set(folderByIdState(targetFolderId), (currVal) => ({ ...currVal, folders: [...currVal.folders, folderId] }));
    }

    function setParentFolderIdInState() {
      set(parentFolderIdByFolderIdState(folderId), targetFolderId);
    }
  });
};
