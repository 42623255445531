/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack } from "@mui/material";
import { ChangeContentViewModeButton } from "@taketurns-components/collaboration/Shared/button/ChangeContentViewModeButton";
import { AddFilesButton } from "@taketurns-components/collaboration/Shared/content/AddFilesButton";
import { AddFolderButton } from "@taketurns-components/collaboration/Shared/content/AddFolderButton";
import { AddRequestButton } from "@taketurns-components/collaboration/Shared/content/AddRequestButton";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import { UploadsProgressIndicator } from "../../../../../document/DocumentUpload/UploadsProgressIndicator/UploadsProgressIndicator";
import { DownloadTurnContent } from "../DownloadTurnContent";

interface DesktopContentFooterMenuProps {
  canUserAddFiles: boolean;
  canUserAddFolders: boolean;
  canUserAddRequests: boolean;
  displayContentAsCard: boolean;
  onToggleViewModeClick: () => unknown;
  isEmptyContent?: boolean;
  uploadIds: number[];
  attachFiles: (files: FileList) => Promise<void>;
  addFolder: ReturnType<typeof useAddFolderRule>["addFolder"];
  addRequest: ReturnType<typeof useAddRequestRule>["addRequest"];
}

export const DesktopContentFooterMenu = (props: DesktopContentFooterMenuProps) => {
  const {
    canUserAddFiles,
    canUserAddFolders,
    canUserAddRequests,
    displayContentAsCard,
    onToggleViewModeClick,
    isEmptyContent,
    uploadIds,
    attachFiles,
    addFolder,
    addRequest,
  } = props;
  return (
    <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} width={"100%"}>
      <Stack direction={"row"} spacing={{ xs: 1.5, lg: 3 }}>
        {canUserAddFiles && <AddFilesButton processFiles={attachFiles} longWordingMode />}
        {canUserAddFolders && <AddFolderButton addFolder={addFolder} longWordingMode />}
        {canUserAddRequests && <AddRequestButton addRequest={addRequest} longWordingMode />}
        {!isEmptyContent && <DownloadTurnContent />}
      </Stack>
      <Box display={"flex"}>
        {uploadIds.length > 0 && <UploadsProgressIndicator uploadIds={uploadIds} />}
        {!isEmptyContent && (
          <ChangeContentViewModeButton onClick={onToggleViewModeClick} cardView={displayContentAsCard} />
        )}
      </Box>
    </Stack>
  );
};
