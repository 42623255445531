/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, KeyboardEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthenticationButtons } from "@taketurns-app/pages/public/components/AuthenticationButtons";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { setEmailVerificationData } from "@taketurns-repositories/webapp/sessionStorage/emailVerificationData";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useResolveErrorMessageToBeDisplayedRule } from "@taketurns-rules/commons/util/useResolveErrorMessageToBeDisplayedRule";
import { useExtendLocationStateWithEmailRule } from "@taketurns-rules/webapp/commands/useExtendLocationStateWithEmailRule";
import { useResendVerificationCodeRule } from "@taketurns-rules/webapp/commands/useResendVerificationCodeRule";
import { PageTitle, useSetDocumentTitleRule } from "@taketurns-rules/webapp/commands/useSetDocumentTitleRule";
import { getInvitationToOrganizationRule } from "@taketurns-rules/webapp/queries/getInvitationToOrganizationRule";
import { useEmailFromLocationStateRule } from "@taketurns-rules/webapp/queries/useEmailFromLocationStateRule";
import { useInvitationEmailRule } from "@taketurns-rules/webapp/queries/useInvitationEmailRule";
import { TakeTurnsDefaultButton } from "../../../components/commons/button";
import { SignInSignUpOrganizationInvitationHeader } from "../../../components/user/Organization/SignInSignUpOrganizationInvitationHeader/SignInSignUpOrganizationInvitationHeader";
import { LinkStyled } from "../../../components/webapp/LinkStyled";
import { MobileCardLayout } from "../../layouts/public/MobileCardLayout";
import { UserAuthenticationLayout } from "../../layouts/public/UserAuthenticationLayout";
import { useAuthContext } from "../../routing/AuthProvider";
import { PUBLIC_ROUTES } from "../../routing/routes/publicRoutes.constants";
import { RegisterOrLoginCard } from "./components/RegisterOrLoginCard";
import { RegisterOrLoginHeader } from "./components/RegisterOrLoginHeader";

export const SignIn = () => {
  const { t } = useWebAppTranslations("signin");
  const isOnMobile = useIsOnMobileRule();

  useSetDocumentTitleRule(PageTitle.SIGNIN);

  if (isOnMobile) {
    return (
      <MobileCardLayout>
        <RegisterOrLoginHeader text={t("text")} secondaryText={<LinkToSignupPage />} />
        <SignInContent />
      </MobileCardLayout>
    );
  }

  const invitationToOrganization = getInvitationToOrganizationRule();
  return (
    <Fragment>
      {invitationToOrganization && (
        <SignInSignUpOrganizationInvitationHeader organizationName={invitationToOrganization.organization.name} />
      )}
      <UserAuthenticationLayout className="SignIn" maxWidth={"sm"}>
        <RegisterOrLoginHeader text={t("text")} secondaryText={<LinkToSignupPage />} />
        <RegisterOrLoginCard>
          <SignInContent />
        </RegisterOrLoginCard>
      </UserAuthenticationLayout>
    </Fragment>
  );
};

const LinkToSignupPage = () => {
  const { search, state } = useLocation();
  const { t } = useWebAppTranslations("signin");
  return (
    <Typography variant="caption" color={TakeTurnsColors.darkGray}>
      {t("or") + " "}
      <LinkStyled to={PUBLIC_ROUTES.SIGN_UP + search} state={state}>
        {t("secondaryText")}
      </LinkStyled>
    </Typography>
  );
};

const SignInContent = () => {
  const { t } = useWebAppTranslations("signin");

  return (
    <Stack direction="column" spacing={2.5}>
      <SignInInvitationHeaderMessage />
      <SignInForm />
      <Divider />
      <AuthenticationButtons
        textSlot={
          <Typography variant={"caption"} color={TakeTurnsColors.darkGray}>
            {t("signInWith")}
          </Typography>
        }
      />
    </Stack>
  );
};

const SignInInvitationHeaderMessage = () => {
  const { t } = useWebAppTranslations("signin");
  const invitationEmail = useInvitationEmailRule();
  if (invitationEmail) {
    return (
      <Typography fontSize={14}>
        {t("invitationSentTo.message")}{" "}
        <span style={{ color: TakeTurnsColors.darkBlue, fontWeight: "bold" }}>{invitationEmail}</span>
        {". "}
        {t("invitationSentTo.signin")}
      </Typography>
    );
  }
  return null;
};

const SignInError = (props: { errorMessage: string }) => {
  return <Typography color="red">{useResolveErrorMessageToBeDisplayedRule(props.errorMessage)}</Typography>;
};

const SignInForm = () => {
  const { t } = useWebAppTranslations("signin");
  const invitationEmail = useInvitationEmailRule();
  const emailFromLocationState = useEmailFromLocationStateRule();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
  const isOnMobile = useIsOnMobileRule();

  const { resendVerificationCode } = useResendVerificationCodeRule(email);
  const navigate = useNavigate();
  const { login, loginLoading, loginError } = useAuthContext();

  useEffect(() => {
    setEmail(emailFromLocationState || invitationEmail || "");
  }, [emailFromLocationState, invitationEmail]);

  useEffect(() => {
    if (passwordIsEmpty && password.length > 0) {
      setPasswordIsEmpty(false);
    }
  }, [password, passwordIsEmpty]);

  useEffect(() => {
    const COGNITO_ERROR_USER_NOT_CONFIRMED_EXCEPTION = "UserNotConfirmedException";
    if (email && loginError?.code === COGNITO_ERROR_USER_NOT_CONFIRMED_EXCEPTION) {
      setEmailVerificationData({
        email: email,
        password: password,
        origin: "signin",
      });
      resendVerificationCode().then(() => navigate(PUBLIC_ROUTES.VERIFY_ACCOUNT));
    }
  }, [loginError, email, password, resendVerificationCode, navigate]);

  return (
    <Fragment>
      <Stack
        component="form"
        noValidate
        onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (email.length > 0 && password.length > 0) loginIfPasswordFilled();
          }
        }}
        spacing={2.5}
      >
        <TextField
          data-cy="signInForm.email"
          placeholder={t("email")}
          value={email}
          autoComplete="username"
          type="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          data-cy="signInForm.password"
          required
          error={passwordIsEmpty}
          helperText={passwordIsEmpty && t("emptyPassword")}
          placeholder={t("password")}
          type="password"
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
        />
      </Stack>
      {loginError && <SignInError errorMessage={loginError.message} />}
      <Stack direction="row" justifyContent="space-between" justifyItems="flex-end" alignItems="center">
        <FormGroup>
          <FormControlLabel
            label={<p>{t("rememberMe")}</p>}
            control={
              <Checkbox
                size="small"
                disableRipple
                disableFocusRipple
                value=""
                style={{ color: TakeTurnsColors.lightGray }}
              />
            }
          />
        </FormGroup>
        {isOnMobile ? <ForgotPasswordLink email={email} /> : <SignInButton />}
      </Stack>
      <Stack direction="row">{isOnMobile ? <SignInButton /> : <ForgotPasswordLink email={email} />}</Stack>
    </Fragment>
  );

  async function loginIfPasswordFilled() {
    if (password.length === 0) {
      setPasswordIsEmpty(true);
      return;
    }
    await login(email.toLowerCase(), password);
  }

  function SignInButton() {
    if (isOnMobile)
      return (
        <TakeTurnsDefaultButton
          data-cy="signInForm.submit"
          disabled={loginLoading}
          onClick={loginIfPasswordFilled}
          startIcon={loginLoading && <CircularProgress size={12} color={"secondary"} />}
          fullWidth
          style={{
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          {t("signIn")}
        </TakeTurnsDefaultButton>
      );

    return (
      <TakeTurnsDefaultButton
        data-cy="signInForm.submit"
        disabled={loginLoading}
        onClick={loginIfPasswordFilled}
        startIcon={loginLoading && <CircularProgress size={12} color={"secondary"} />}
      >
        {t("signIn")}
      </TakeTurnsDefaultButton>
    );
  }

  function ForgotPasswordLink({ email }: { email: string }) {
    const { search } = useLocation();
    const extendLocationStateWithEmailRule = useExtendLocationStateWithEmailRule();
    return (
      <Typography variant="caption" color="secondary">
        <Link to={{ pathname: PUBLIC_ROUTES.RESET_PASSWORD, search }} state={extendLocationStateWithEmailRule(email)}>
          {t("forgotPassword")}
        </Link>
      </Typography>
    );
  }
};
