/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, Stack } from "@mui/material";
import React from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { AskToReviewAttachmentSwitch } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AskToReviewAttachmentSwitch";
import { RemoveAttachmentButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/RemoveAttachmentButton";
import { AttachmentDetailsHeaderDownloadSaveCancelButtons } from "@taketurns-components/collaboration/Shared/content/AttachmentDetailsHeaderDownloadSaveCancelButtons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";

interface DetailsActionsBarProps {
  canMarkForReview: boolean;
  canDelete: boolean;
  isDeleteAttachmentDisabled: boolean;
  attachment: Attachment;
  collaborationId: string;
  selectedRevisionId: string;
  isPdfUpdated: boolean;
  onSaveRevisionButtonClick: () => void;
  onCancelAnnotationsButtonClick: () => void;
}

export const MobileAttachmentDetailsActionsBar = ({
  canMarkForReview,
  canDelete,
  isDeleteAttachmentDisabled,
  attachment,
  collaborationId,
  selectedRevisionId,
  isPdfUpdated,
  onSaveRevisionButtonClick,
  onCancelAnnotationsButtonClick,
}: DetailsActionsBarProps) => {
  const { t } = useAttachmentDetailsTranslation();

  const shouldShowDivider = canMarkForReview && canDelete;

  return (
    <Stack direction="row" paddingX={1} paddingY={2} alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <AttachmentDetailsHeaderDownloadSaveCancelButtons
          documentId={attachment.document.id}
          collaborationId={collaborationId}
          revisionId={selectedRevisionId}
          onSaveRevisionButtonClick={onSaveRevisionButtonClick}
          onCancelAnnotationsButtonClick={onCancelAnnotationsButtonClick}
          isPdfUpdated={isPdfUpdated}
        />
        {canMarkForReview && <AskToReviewAttachmentSwitch attachment={attachment} />}
        {shouldShowDivider && <Divider orientation="vertical" flexItem />}
      </Stack>
      {canDelete && (
        <RemoveAttachmentButtonWithTooltip
          title={t("cannotDeleteLockedAttachment")}
          deleteAttachmentDisabled={isDeleteAttachmentDisabled}
          attachment={attachment}
        />
      )}
    </Stack>
  );
};

function RemoveAttachmentButtonWithTooltip({
  deleteAttachmentDisabled,
  attachment,
  title,
}: {
  title: string;
  deleteAttachmentDisabled: boolean;
  attachment: Attachment;
}) {
  return <RemoveAttachmentButton title={title} attachment={attachment} disabled={deleteAttachmentDisabled} />;
}
