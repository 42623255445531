/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { AddAnyContentButton } from "@taketurns-components/collaboration/Shared/MobileAddContent/AddAnyContentButton";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import { UploadsProgressIndicator } from "../../../../../document/DocumentUpload/UploadsProgressIndicator/UploadsProgressIndicator";
import { DownloadTurnContent } from "../DownloadTurnContent";

interface MobileContentFooterMenuProps {
  canUserAddFiles: boolean;
  canUserAddFolders: boolean;
  canUserAddRequests: boolean;
  isEmptyContent?: boolean;
  uploadIds: number[];
  attachFiles: (files: FileList) => Promise<void>;
  addFolder: ReturnType<typeof useAddFolderRule>["addFolder"];
  addRequest: ReturnType<typeof useAddRequestRule>["addRequest"];
}

export const MobileContentFooterMenu = (props: MobileContentFooterMenuProps) => {
  const {
    canUserAddFiles,
    canUserAddFolders,
    canUserAddRequests,
    isEmptyContent,
    uploadIds,
    attachFiles,
    addFolder,
    addRequest,
  } = props;

  const canUserAddAnyContent = canUserAddFiles || canUserAddFolders || canUserAddRequests;

  return (
    <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} width={"100%"}>
      <Stack direction={"row"} spacing={{ xs: 1.5, lg: 3 }}>
        {canUserAddAnyContent && (
          <AddAnyContentButton
            canUserAddFiles={canUserAddFiles}
            canUserAddFolders={canUserAddFolders}
            canUserAddRequests={canUserAddRequests}
            attachFiles={attachFiles}
            addFolder={addFolder}
            addRequest={addRequest}
          />
        )}
        {!isEmptyContent && <DownloadTurnContent />}
      </Stack>
      {uploadIds.length > 0 && <UploadsProgressIndicator uploadIds={uploadIds} />}
    </Stack>
  );
};
