/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useSetPassword } from "@taketurns-repositories/user/graphql/mutations/useSetPassword";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useSetPasswordRule = () => {
  const { setUserPassword: setUserPasswordCommand, loadingSetUserPassword } = useSetPassword();
  const { t } = useUserSharedTranslation();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayAndLogNotificationErrorRule = useDisplayAndLogNotificationErrorRule();

  const setPassword = async (password: string) => {
    try {
      await setUserPasswordCommand(password);
      displayNotificationSuccess(t("UserSettings.passwordChanged"));
    } catch (error) {
      displayAndLogNotificationErrorRule(error);
    }
  };

  return { setPassword, loadingSetUserPassword };
};
