/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, IconButton, List, Stack, SxProps, Typography } from "@mui/material";
import { Attachment, ContentStatus, Folder, Request } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { IconArrowLeft, IconFolder } from "@taketurns-components/commons/icons";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { AttachmentListItem } from "./AttachmentListItem/AttachmentListItem";
import { FolderListItem } from "./FolderListItem/FolderListItem";
import { RequestListItem } from "./RequestListItem/RequestListItem";

interface ContentListViewProps {
  backBreadcrumbLabel: string;
  attachments?: Attachment[];
  requests?: Request[];
  folders?: Folder[];
  displayUpdatedOnly?: boolean;
  editable: boolean;
  dropZoneConfigBuilder?: (folderId: string) => {
    sx: SxProps;
    multiple: boolean;
    handleFilesDropping: (files: File[]) => void;
    className: string;
  };
}

export const ContentListView = (props: ContentListViewProps) => {
  const { moveBackward, isCurrentFolderRootFolder } = useBrowseContentRule();
  const { isCollaborationClosed } = useIsCollaborationClosedRule();

  const isOnMobile = useIsOnMobileRule();

  return (
    <List
      disablePadding
      dense
      sx={{
        paddingBottom: "50px",
        height: { xs: "auto", md: "100%" },
        overflow: { xs: "initial", md: "auto" },
      }}
    >
      {!isOnMobile && <Divider />}
      {!isCurrentFolderRootFolder && (
        <TakeTurnsListItem
          titleSlot={<ListItemBackButtonWithFolderName folderName={props.backBreadcrumbLabel} onClick={moveBackward} />}
        />
      )}
      {props.attachments
        ?.filter((attachment) => !props.displayUpdatedOnly || attachment.prevStatus !== ContentStatus.Unchanged)
        ?.map((attachment) => (
          <AttachmentListItem key={attachment.id} attachmentId={attachment.id} editable={props.editable} />
        ))}
      {props.folders
        ?.filter((folder) => !props.displayUpdatedOnly || folder.prevStatus !== ContentStatus.Unchanged)
        ?.map((subFolder) => (
          <FolderListItem
            key={subFolder.id}
            folderId={subFolder.id}
            editable={props.editable}
            dropZoneConfig={props.dropZoneConfigBuilder(subFolder.id)}
          />
        ))}
      {props.requests
        ?.filter(
          (request) =>
            !isCollaborationClosed && (!props.displayUpdatedOnly || request.prevStatus !== ContentStatus.Unchanged),
        )
        ?.map((request, index) => (
          <RequestListItem key={`${request.name}_${index}`} requestId={request.id} editable={props.editable} />
        ))}
    </List>
  );
};

const ListItemBackButtonWithFolderName = ({ onClick, folderName }: { onClick: () => void; folderName: string }) => {
  return (
    <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
      <IconButton size="small" disableRipple onClick={onClick}>
        <IconArrowLeft size={16} color={TakeTurnsColors.grayButton} />
      </IconButton>
      <IconFolder size={16} color={TakeTurnsColors.grayButton} />
      <Typography variant="body2" noWrap>
        {folderName}
      </Typography>
    </Stack>
  );
};
