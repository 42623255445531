/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, Fragment } from "react";
import { Attachment, ContentStatus, ContentStatus as ContentStatusEnum } from "@taketurns/taketurns-graphql-repository";
import {
  AttachmentMoveDestinationDialogInCollaborationView,
} from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/AttachmentMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { DropZoneConfig } from "@taketurns-components/collaboration/Shared/FilesDragAndDrop";
import { FilesDragAndDropWithOverlay } from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useOpenAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useOpenAttachmentDetailsRule";
import {
  useUploadRevisionBeforeOpeningAttachmentDetailsRule,
} from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useUploadRevisionBeforeOpeningAttachmentDetailsRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetAttachmentContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/attachment/useGetAttachmentContextMenuConfigurationRule";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useSubscribeToMarkUnmarkAsReviewedAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkAsReviewedAttachmentRule";
import { useSubscribeToMarkUnmarkForReviewAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkForReviewAttachmentRule";
import { useSubscribeToSetAttachmentLockRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToSetAttachmentLockRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSubscribeOnDocumentChangedRule } from "@taketurns-rules/document/subscriptions/useSubscribeOnDocumentChangedRule";
import { DesktopAttachmentListItem } from "./DesktopAttachmentListItem";
import { MobileAttachmentListItem } from "./MobileAttachmentListItem";

interface AttachmentListItemProps {
  attachmentId: string;
  editable: boolean;
}

export const AttachmentListItem = ({ attachmentId, editable }: AttachmentListItemProps) => {
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const attachment = useGetAttachmentRule(attachmentId);
  const attachmentContextMenuConfiguration = useGetAttachmentContextMenuConfigurationRule(
    attachment,
    openMoveDestinationDialog,
  );

  if (!editable || hasAttachmentBeenRemoved(attachment)) {
    return <ListItem attachmentId={attachmentId} editable={false} />;
  } else {
    const ListItemWithContextMenu = WithContextMenu<AttachmentListItemProps>(
      ListItem,
      attachmentContextMenuConfiguration,
    );
    return (
      <Fragment>
        <ListItemWithContextMenu attachmentId={attachmentId} editable={editable} />
        <AttachmentMoveDestinationDialogInCollaborationView
          attachmentId={attachment.id}
          open={isMoveDestinationDialogOpen}
          onClose={closeMoveDestinationDialog}
        />
      </Fragment>
    );
  }
};

const hasAttachmentBeenRemoved = (attachment: Attachment) =>
  attachment.status === ContentStatusEnum.Removed || attachment.prevStatus === ContentStatusEnum.Removed;

const ListItem = forwardRef(
  ({ attachmentId, editable }: AttachmentListItemProps, ref: ForwardedRef<HTMLLIElement | null>) => {
    const attachment = useGetAttachmentRule(attachmentId);
    const { isCollaborationClosed } = useIsCollaborationClosedRule();
    const shouldDisplayPendingUpdatesMarker =
      !isCollaborationClosed && attachment.status !== ContentStatusEnum.Unchanged;
    const shouldDisplayLastUpdatesMarker =
      !isCollaborationClosed && attachment.prevStatus && attachment.prevStatus !== ContentStatus.Unchanged;
    const isOnMobile = useIsOnMobileRule();

    useSubscribeToMarkUnmarkForReviewAttachmentRule(attachment.id);
    useSubscribeToMarkUnmarkAsReviewedAttachmentRule(attachment.id);
    useSubscribeToSetAttachmentLockRule(attachment.id);
    useSubscribeOnDocumentChangedRule(attachment.id);

    const openAttachmentDetailsAndUploadRevision = useUploadRevisionBeforeOpeningAttachmentDetailsRule(attachment);
    const dropZoneConfig: DropZoneConfig = {
      handleFilesDropping: (files) => openAttachmentDetailsAndUploadRevision(files[0]),
      className: "AttachmentListItem__DropZone",
      sx: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
    };

    const isAttachmentRemoved = hasAttachmentBeenRemoved(attachment);
    const openAttachmentDetails = useOpenAttachmentDetailsRule(attachment.id);

    const itemBackgroundColor = shouldDisplayPendingUpdatesMarker
      ? TakeTurnsColors.updatedElementBackground
      : attachment.locked
        ? TakeTurnsColors.background
        : undefined;

    const ItemComponent = isOnMobile ? MobileAttachmentListItem : DesktopAttachmentListItem;
    const item = (
      <ItemComponent
        backgroundColor={itemBackgroundColor}
        attachment={attachment}
        isAttachmentRemoved={isAttachmentRemoved}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        openAttachmentDetails={openAttachmentDetails}
        color={TakeTurnsColors.otherParty}
        ref={ref}
      />
    );

    if (!editable || isAttachmentRemoved) {
      return item;
    }
    return (
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        {item}
      </FilesDragAndDropWithOverlay>
    );
  },
);
ListItem.displayName = "AttachmentListItem";
