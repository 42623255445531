/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ResourceLanguage } from "i18next";
import acceptOrganizationInvitation from "./accept-organization-invitation.json";
import collaborationCreation from "./collaboration/collaboration-creation.json";
import collaborationList from "./collaboration/collaboration-list.json";
import collaboration from "./collaboration/collaboration.json";
import simpleFileRequest from "./collaboration/simple-file-request.json";
import simpleFileSharing from "./collaboration/simple-file-sharing.json";
import completeProfile from "./complete-profile.json";
import helpSection from "./help-section.json";
import language from "./language.json";
import passwordlessCollaborationAccess from "./passwordless-collaboration-access.json";
import passwordlessSignInForm from "./passwordless-signin.json";
import plans from "./plans.json";
import publicAppbar from "./public-appbar.json";
import resetPassword from "./reset-password.json";
import signin from "./signin.json";
import titleMeta from "./title-meta.json";
import settings from "./user/settings.json";
import welcomeDialog from "./welcome-dialog.json";

export const TranslationsFR: ResourceLanguage = {
  signin,
  settings,
  language,
  helpSection,
  completeProfile,
  collaboration,
  "reset-password": resetPassword,
  "collaboration-list": collaborationList,
  plans,
  "accept-organization-invitation": acceptOrganizationInvitation,
  "welcome-dialog": welcomeDialog,
  "title-meta": titleMeta,
  "collaboration-creation": collaborationCreation,
  "public-appbar": publicAppbar,
  passwordlessCollaborationAccess,
  "simple-file-sharing": simpleFileSharing,
  "simple-file-request": simpleFileRequest,
  "passwordless-signin": passwordlessSignInForm,
};
