/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar } from "@mui/material";
import { useEffect } from "react";
import i18n from "@taketurns-i18n/i18n";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useChangePreferredLanguageRule } from "@taketurns-rules/user/commands/useChangePreferredLanguageRule";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";
import { UserAvatarExtended } from "./UserAvatarExtended";
import { UserAvatarLoadingIndicator } from "./UserAvatarLoadingIndicator";
import { UserRoundedAvatar } from "./UserRoundedAvatar";

interface UserAvatarProps {
  displayInfo?: boolean;
  onAvatarClick?: () => void;
}

export const UserAvatar = (props: UserAvatarProps) => {
  const { displayInfo, onAvatarClick } = props;

  const { connectedUser, errorOnFetchingConnectedUser, loadingConnectedUser } = useFetchConnectedUserRule();

  const { changePreferredLanguage } = useChangePreferredLanguageRule();

  useEffect(() => {
    if (connectedUser) {
      const { preferences } = connectedUser;
      if (!preferences?.language) {
        changePreferredLanguage(i18n.language);
      } else if (preferences.language !== i18n.language) {
        i18n.changeLanguage(preferences.language);
      }
    }
  }, [connectedUser]);

  if (loadingConnectedUser) {
    return <UserAvatarLoadingIndicator displayInfo={displayInfo} />;
  }

  if (errorOnFetchingConnectedUser) {
    console.error(errorOnFetchingConnectedUser.message);
    return (
      <Avatar
        onClick={onAvatarClick}
        alt="Profile picture"
        sx={{
          backgroundColor: TakeTurnsColors.error,
          width: 30,
          height: 30,
          "&:hover": { cursor: "pointer" },
        }}
      />
    );
  }

  if (!connectedUser) {
    return null;
  }

  if (displayInfo) {
    return <UserAvatarExtended user={connectedUser} onAvatarClick={onAvatarClick} />;
  }
  return <UserRoundedAvatar user={connectedUser} onAvatarClick={onAvatarClick} />;
};
