/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { MobileCardLayout } from "../../layouts/public/MobileCardLayout";
import { UserAuthenticationLayout } from "../../layouts/public/UserAuthenticationLayout";
import { PasswordlessSignInForm } from "./components/PasswordlessSignInForm";
import { RegisterOrLoginCard } from "./components/RegisterOrLoginCard";
import { RegisterOrLoginHeader } from "./components/RegisterOrLoginHeader";

export const ResetPassword = () => {
  const { t } = useWebAppTranslations("reset-password");
  const isOnMobile = useIsOnMobileRule();
  const { state, search } = useLocation();

  const LinkToSignInPage = (
    <Typography variant="caption" color={TakeTurnsColors.darkGray}>
      {t("or") + " "}
      <LinkStyled to={PUBLIC_ROUTES.SIGN_IN + search} state={state}>
        {t("signIn")}
      </LinkStyled>
    </Typography>
  );

  if (isOnMobile) {
    return (
      <MobileCardLayout>
        <RegisterOrLoginHeader text={t("title")} secondaryText={LinkToSignInPage} />
        <PasswordlessSignInForm isInForgotPasswordProcess />
      </MobileCardLayout>
    );
  }

  return (
    <UserAuthenticationLayout maxWidth={"sm"}>
      <RegisterOrLoginHeader text={t("title")} secondaryText={LinkToSignInPage} />
      <RegisterOrLoginCard>
        <PasswordlessSignInForm isInForgotPasswordProcess />
      </RegisterOrLoginCard>
    </UserAuthenticationLayout>
  );
};
