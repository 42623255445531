/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import { ComponentType } from "react";
import { useAttachFilesInCurrentFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useAttachFilesInCurrentFolderRule";
import { DropZoneConfig, FilesDragAndDrop } from "../../../Shared/FilesDragAndDrop";

export const ContentWithDropZone = ({ Content }: { Content: ComponentType }) => {
  const attachFilesRule = useAttachFilesInCurrentFolderRule();
  const attachFiles = async (files: File[]) => {
    await attachFilesRule(Array.from(files));
  };

  const dropZoneWithBackdropConfig: DropZoneConfig = {
    handleFilesDropping: attachFiles,
    className: "ContentWithDropZone__DropZoneContainer",
    sx: {
      flex: 1,
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
  };

  return (
    <FilesDragAndDrop ContainerComponent={Box} dropZoneConfig={dropZoneWithBackdropConfig}>
      <Content />
    </FilesDragAndDrop>
  );
};
