/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface DocumentChipProps {
  label: string;
  icon?: ReactNode;
  backgroundColor?: string;
}

export const DocumentChip = ({ label, icon, backgroundColor }: DocumentChipProps) => (
  <DocumentChipContainer backgroundColor={backgroundColor}>
    {icon}
    <Typography fontSize={9} noWrap>
      {label}
    </Typography>
  </DocumentChipContainer>
);

const DocumentChipContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
  backgroundColor: props.backgroundColor ?? TakeTurnsColors.darkBlue,
  borderRadius: "5px",
  padding: "2px 10px",
  width: "fit-content",
  minWidth: 0,
  textAlign: "center",
  textTransform: "uppercase",
  color: "white",
  fontWeight: 600,
}));
