/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Attachment, ContentStatus } from "@taketurns/taketurns-graphql-repository";
import { AddAttachmentRevisionButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AddAttachmentRevisionButton";
import { RevisionActions } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionList/RevisionActions";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useIsLastUpdateMadeByConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useIsLastUpdateMadeByConnectedUserPartyRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconInfo } from "../../../../../commons/icons";
import { DocumentExtension } from "../../../../../document/DocumentView/DocumentExtension";
import { MobileAttachmentRevisionListButton } from "../DocumentRevisionList/MobileAttachmentRevisionListButton";

interface DetailsHeaderProps {
  attachment: Attachment;
  selectedRevisionId: string;
  canUploadRevision: boolean;
  canEditDocumentDetails: boolean;
}

export const MobileAttachmentDetailsHeader = (props: DetailsHeaderProps) => {
  const { isLastUpdateMadeByConnectedUserParty } = useIsLastUpdateMadeByConnectedUserPartyRule();
  const shouldDisplayOtherPartyLastUpdate = !!(
    !isLastUpdateMadeByConnectedUserParty &&
    props.attachment.prevStatus &&
    props.attachment.prevStatus !== ContentStatus.Unchanged
  );

  return (
    <Stack m={3} spacing={2}>
      {props.canUploadRevision && (
        <MobileAttachmentRevisionActions attachment={props.attachment} selectedRevisionId={props.selectedRevisionId} />
      )}
      <Stack direction={"row"} spacing={1} justifyContent="space-between">
        <MobileAttachmentRevisionListButton attachment={props.attachment} canEdit={props.canEditDocumentDetails} />

        <Stack direction={"row"} spacing={1} alignItems="center">
          {shouldDisplayOtherPartyLastUpdate && <ContentElementLastUpdate status={props.attachment.prevStatus} />}
          <DocumentExtension extension={props.attachment.document.extension} size={props.attachment.document.size} />

          {props.attachment.document.description && (
            <Tooltip title={props.attachment.document.description} enterTouchDelay={100}>
              <IconButton size={"small"}>
                <IconInfo />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

interface MobileAttachmentRevisionActionsProps {
  attachment: Attachment;
  selectedRevisionId: string;
}

const MobileAttachmentRevisionActions = (props: MobileAttachmentRevisionActionsProps) => {
  const { t } = useAttachmentDetailsTranslation();

  const hasPendingRevision = props.attachment.hasAttachmentPendingRevision;
  const isLast = props.selectedRevisionId === props.attachment.revisionId;
  if (!hasPendingRevision) {
    return (
      <Stack direction="row" justifyContent="center">
        <AddAttachmentRevisionButton />
      </Stack>
    );
  }

  if (isLast && hasPendingRevision) {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color={TakeTurnsColors.darkGray} variant={"caption"}>
          {t("revision.uploaded")}
        </Typography>
        <RevisionActions
          attachmentId={props.attachment.id}
          revisionNumber={props.attachment.document.revisions.length}
        />
      </Stack>
    );
  }
};
