/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { passwordFormSchema, refinePasswordWithForbiddenFields } from "@taketurns-rules/commons/passwordValidationRule";

type PasswordFormFields = z.infer<typeof passwordFormSchema>;

export const useSetPasswordFormValidationRule = (connectedUser: ConnectedUser) => {
  const refinedChangePasswordFormSchema = refinePasswordWithForbiddenFields(passwordFormSchema, {
    forbiddenValues: [
      connectedUser.email.toLowerCase(),
      connectedUser.firstName?.toLowerCase(),
      connectedUser.lastName?.toLowerCase(),
    ],
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
  } = useForm<PasswordFormFields>({
    resolver: zodResolver(refinedChangePasswordFormSchema),
  });
  return { handleSubmit, control, errors, trigger, reset };
};
