/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { PrepareContentDownloadInput } from "@taketurns/taketurns-graphql-repository";

export const useDownloadContent = () => {
  const mutation = gql`
    mutation prepareContentDownload($input: PrepareContentDownloadInput!) {
      prepareContentDownload(input: $input) {
        downloadId
      }
    }
  `;
  const [prepareContentDownload, prepareContentDownloadResult] = useMutation(mutation);

  const downloadTurnContent = async (input: PrepareContentDownloadInput) => {
    return prepareContentDownload({ variables: { input } });
  };

  return {
    downloadTurnContent,
    downloadTurnContentLoading: prepareContentDownloadResult.loading,
    downloadTurnContentError: prepareContentDownloadResult.error,
    downloadId: prepareContentDownloadResult.data?.prepareContentDownload?.downloadId,
  };
};
