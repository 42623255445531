/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, List } from "@mui/material";
import { TakeTurnsListItemSkeleton } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItemSkeleton";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useGetDisplayedCollaborationRootFolderId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationRootFolderId";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { FunctionComponent, useCallback, useRef, useState } from "react";
import { CurrentFolderListItemWithBackButton } from "./CurrentFolderListItemWithBackButton";

export interface DestinationFolderListProps {
  onSelectedFolderChange: (destinationFolderPath: string) => void;
  excludedFolderId?: string;
  getDestinationFolder: (destinationFolderId: string) => {
    destinationFolder: DestinationFolder;
    loadingDestinationFolder: boolean;
    errorWhileFetchingDestinationFolder: boolean;
  };
  FolderListItem: FunctionComponent<{ destinationFolder: DestinationFolder; onFolderClick: () => void }>;
}

export const DestinationFolderList = ({
  onSelectedFolderChange,
  excludedFolderId,
  FolderListItem,
  getDestinationFolder,
}: DestinationFolderListProps) => {
  const rootFolderId = useGetDisplayedCollaborationRootFolderId();
  const folderIdsPathRef = useRef<string[]>([]);
  const [selectedDestinationFolderId, setSelectedDestinationFolderId] = useState(rootFolderId);
  const { destinationFolder, loadingDestinationFolder, errorWhileFetchingDestinationFolder } =
    getDestinationFolder(selectedDestinationFolderId);

  const isSelectedFolderRootFolder = selectedDestinationFolderId === rootFolderId;

  const onBackClicked = useCallback(() => {
    const parentFolderId = folderIdsPathRef.current[folderIdsPathRef.current.length - 1];
    folderIdsPathRef.current = folderIdsPathRef.current.slice(0, folderIdsPathRef.current.length - 1);
    setSelectedDestinationFolderId(parentFolderId);
  }, []);

  const onDestinationFolderListItemClicked = useCallback((folderId: string) => {
    folderIdsPathRef.current = [...folderIdsPathRef.current, selectedDestinationFolderId];
    setSelectedDestinationFolderId(folderId);
  }, []);

  if (loadingDestinationFolder) {
    return <TakeTurnsListItemSkeleton />;
  }

  if (errorWhileFetchingDestinationFolder || !destinationFolder) {
    errorWhileFetchingDestinationFolder && console.error(errorWhileFetchingDestinationFolder);
    return <SomethingWentWrong />;
  }

  onSelectedFolderChange(destinationFolder.id);

  return (
    <List disablePadding dense sx={{ padding: "0 24px", paddingBottom: "50px", height: "100%", overflow: "auto" }}>
      <Divider />
      {!isSelectedFolderRootFolder && (
        <CurrentFolderListItemWithBackButton folderName={destinationFolder.name} onFolderClick={onBackClicked} />
      )}
      {destinationFolder.folders
        .filter((folder) => folder.id !== excludedFolderId)
        .map((folder) => (
          <FolderListItem
            key={folder.id}
            destinationFolder={folder}
            onFolderClick={() => onDestinationFolderListItemClicked(folder.id)}
          />
        ))}
    </List>
  );
};
