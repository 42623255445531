/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { forwardRef } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { DocumentExtension } from "@taketurns-components/document/DocumentView/DocumentExtension";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { ListItemTitle } from "../ListItemTitle";
import { AttachmentMarkersInList } from "./AttachmentMarkersInList";

interface DesktopAttachmentListItemProps {
  attachment: Attachment;
  isAttachmentRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  openAttachmentDetails: () => void;
  color: string;
  backgroundColor: string;
}

export const DesktopAttachmentListItem = forwardRef<HTMLLIElement | null, DesktopAttachmentListItemProps>(
  (props: DesktopAttachmentListItemProps, ref) => {
    const { color: lastUpdateColor } = useFetchLastUpdateColor();

    return (
      <TakeTurnsListItem
        ref={ref}
        titleSlot={
          <ListItemTitle title={props.attachment.document.name} shouldStrikeTitle={props.isAttachmentRemoved} />
        }
        subtitleSlot={<AttachmentMarkersInList attachment={props.attachment} />}
        rightEndSlot={
          <DocumentExtension extension={props.attachment.document.extension} size={props.attachment.document.size} />
        }
        middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.attachment.prevStatus} />}
        onClick={props.isAttachmentRemoved ? undefined : props.openAttachmentDetails}
        sx={{
          borderRight: `8px solid ${props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor}`,
          backgroundColor: props.backgroundColor,
          "&:hover": {
            borderRight: `8px solid ${props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor}`,
          },
        }}
      />
    );
  },
);

DesktopAttachmentListItem.displayName = "DesktopAttachmentListItem";
