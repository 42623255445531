/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { updateIsPasswordAlreadySetQuery } from "@taketurns-repositories/user/graphql/mutations/useChangePassword";

export const useSetPassword = () => {
  const SET_PASSWORD = gql`
    mutation setPassword($password: String!) {
      setPassword(password: $password)
    }
  `;
  const [setPasswordMutation, { loading: loadingSetUserPassword }] = useMutation<{
    setPassword: boolean;
  }>(SET_PASSWORD);

  const setUserPassword = (password: string) => {
    return setPasswordMutation({
      variables: { password },
      update: (cache, { data: { setPassword: isPasswordSet } }) =>
        updateIsPasswordAlreadySetQuery(cache, isPasswordSet),
    });
  };
  return { setUserPassword, loadingSetUserPassword };
};
