/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { ComponentType, ForwardedRef, forwardRef, Fragment } from "react";
import { ContentStatus, ContentStatus as ContentStatusEnum, Folder } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdateInCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdateInCard";
import { FolderMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/FolderMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { AttachmentsCounter } from "@taketurns-components/collaboration/Shared/content/AttachmentsCounter";
import { EditFolderNameDialog } from "@taketurns-components/collaboration/Shared/content/EditFolderDialog";
import { FolderUpdatesMarker } from "@taketurns-components/collaboration/Shared/content/Markers";
import { RequestsCounter } from "@taketurns-components/collaboration/Shared/content/RequestsCounter";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useCollaborationViewTranslation } from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import { useRenameFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useRenameFolderRule";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetSubFolderRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetSubFolderRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useGetFolderContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/folder/useGetFolderContextMenuConfigurationRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToFolderChangesRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToFolderChangesRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { CardTitle } from "../shared/components/CardTitle";

interface FolderCardProps {
  folderId: string;
  editable: boolean;
  dropZoneConfig: FilesDragAndDropWithOverlayConfig;
}

const hasFolderBeenRemoved = (folder: Folder) =>
  folder.status === ContentStatusEnum.Removed || folder.prevStatus === ContentStatusEnum.Removed;
const hasFolderBeenRemovedInPreviousTurn = (folder: Folder) => folder.prevStatus === ContentStatusEnum.Removed;

export const FolderCard = ({ folderId, editable, dropZoneConfig }: FolderCardProps) => {
  const folder = useGetSubFolderRule(folderId);
  useSubscribeToFolderChangesRule(folderId);
  const { renameFolder } = useRenameFolderRule(folderId);
  useSubscribeToFolderChangesRule(folder.id);
  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();

  const folderContextMenuConfiguration = useGetFolderContextMenuConfigurationRule(
    folder,
    openEditDialog,
    openMoveDestinationDialog,
  );

  let InnerCard: ComponentType;
  if (!editable || folder.prevStatus === ContentStatusEnum.Removed) {
    InnerCard = () => <InnerFolderCard folderId={folderId} editable={false} dropZoneConfig={dropZoneConfig} />;
  } else {
    const CardWithContextMenu = WithContextMenu<FolderCardProps>(InnerFolderCard, folderContextMenuConfiguration);
    const FolderCardWithContextMenu = () => (
      <CardWithContextMenu folderId={folderId} editable={editable} dropZoneConfig={dropZoneConfig} />
    );
    FolderCardWithContextMenu.displayName = "FolderCardWithContextMenu";
    InnerCard = FolderCardWithContextMenu;
  }

  return (
    <Fragment>
      <InnerCard />
      {editable && !hasFolderBeenRemoved(folder) && (
        <Fragment>
          <EditFolderNameDialog
            open={isEditDialogOpened}
            onClose={closeEditDialog}
            folderName={folder.name}
            renameFolder={renameFolder}
          />
          <FolderMoveDestinationDialogInCollaborationView
            folder={folder}
            open={isMoveDestinationDialogOpen}
            onClose={closeMoveDestinationDialog}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const InnerFolderCard = forwardRef(
  ({ folderId, editable, dropZoneConfig }: FolderCardProps, ref: ForwardedRef<HTMLDivElement | null>) => {
    const folder = useGetSubFolderRule(folderId);
    const { moveIntoFolder } = useBrowseContentRule();
    const moveIntoCardFolder = () => {
      moveIntoFolder(folder.id);
    };

    if (!editable || hasFolderBeenRemoved(folder)) {
      if (hasFolderBeenRemovedInPreviousTurn(folder)) {
        return (
          <FolderCard__Container className="FolderCard__Container" ref={ref} data-cy={`FolderCard.${folder.id}`}>
            <FolderCardInnerContent folder={folder} />
          </FolderCard__Container>
        );
      }
      return (
        <FolderCard__Container__Clickable
          className="FolderCard__Container__Clickable"
          ref={ref}
          onClick={moveIntoCardFolder}
          data-cy={`FolderCard.${folder.id}`}
        >
          <FolderCardInnerContent folder={folder} />
        </FolderCard__Container__Clickable>
      );
    }

    return (
      <FolderCard__Container__Clickable
        className="FolderCard__Container__Clickable"
        ref={ref}
        onClick={moveIntoCardFolder}
        data-cy={`FolderCard.${folder.id}`}
      >
        <FilesDragAndDropWithOverlay roundedDropZone ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
          <FolderCardInnerContent folder={folder} />
        </FilesDragAndDropWithOverlay>
      </FolderCard__Container__Clickable>
    );
  },
);
InnerFolderCard.displayName = "InnerFolderCard";

const FolderCard__Container = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: 170,
  width: 135,
  background: "transparent",
  borderRadius: 10,
});

const FolderCard__Container__Clickable = styled(FolderCard__Container)({
  "&:hover": {
    cursor: "pointer",
    backgroundColor: TakeTurnsColors.hoverBackground,
  },
});

const FolderCardInnerContent = ({ folder }: { folder: Folder }) => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const shouldDisplayPendingUpdatesMarker =
    !isCollaborationClosed && (folder.status !== ContentStatus.Unchanged || !!folder.updateCount);
  const shouldDisplayLastUpdatesMarker =
    !isCollaborationClosed &&
    ((folder.prevStatus && folder.prevStatus !== ContentStatus.Unchanged) || !!folder.prevUpdateCount);
  const lastUpdateStatus = !!folder.prevUpdateCount ? ContentStatus.Updated : folder.prevStatus;
  const shouldDisplayRequestCounter = !isCollaborationClosed && folder.requestCount > 0;
  const shouldDisplayReviewTag = !isCollaborationClosed && folder.attachmentToReviewCount > 0;
  const shouldStrikeTitle = hasFolderBeenRemoved(folder);

  return (
    <Fragment>
      <FolderCard__Header className="FolderCard__Header">
        <FolderCardHeader__Filled
          className="FolderCard__Header__Filled"
          bgcolor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : "transparent"}
        />
        <FolderCardHeader__NotchOrTag
          className="FolderCard__Header__NotchOrTag"
          bgcolor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : "transparent"}
        >
          {shouldDisplayReviewTag && <FolderCardReviewTag />}
        </FolderCardHeader__NotchOrTag>
      </FolderCard__Header>
      <FolderCard__Content
        className="FolderCard__Content"
        bgcolor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : "transparent"}
      >
        <CardTitle title={folder.name} shouldStrikeTitle={shouldStrikeTitle} />
      </FolderCard__Content>
      <FolderCard__LastUpdateStatus
        bgcolor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : "transparent"}
      >
        {shouldDisplayLastUpdatesMarker && <ContentElementLastUpdateInCard status={lastUpdateStatus} />}
      </FolderCard__LastUpdateStatus>
      <FolderCard__FolderStats className="FolderCard__FolderStats">
        <AttachmentsCounter attachmentsCount={folder.attachmentCount} />
        {shouldDisplayRequestCounter && <RequestsCounter requestsCount={folder.requestCount} />}
        {shouldDisplayPendingUpdatesMarker && <FolderUpdatesMarker folder={folder} />}
      </FolderCard__FolderStats>
    </Fragment>
  );
};

const FolderCard__Header = styled(Box)({
  display: "grid",
  gridTemplateColumns: "64px 71px",
  gridTemplateRows: "8px 20px",
  gridTemplateAreas: `
    "filled ."
    "filled notch-or-tag"
  `,
});

const FolderCardHeader__Filled = styled(Box)({
  gridArea: "filled",
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRight: 0,
  borderBottom: 0,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
});

const FolderCardHeader__NotchOrTag = styled(Box)({
  gridArea: "notch-or-tag",
  position: "relative",
  borderTopRightRadius: "10px",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: TakeTurnsColors.background,
    border: `1px solid ${TakeTurnsColors.lightGray}`,
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
});

const FolderCardReviewTag = () => {
  const { t } = useCollaborationViewTranslation();

  return (
    <TakeTurnsFolderPlaceHolder backgroundColor={TakeTurnsColors.otherParty}>
      <Typography fontSize={9} color="white" fontWeight={600}>
        {t("review")}
      </Typography>
    </TakeTurnsFolderPlaceHolder>
  );
};

const TakeTurnsFolderPlaceHolder = styled("div", {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  position: "absolute",
  right: 0,
  marginRight: "-1px",
  minHeight: "20px",
  height: "20px",
  maxHeight: "20px",
  minWidth: "72px",
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderTopRightRadius: 10,
  borderBottomLeftRadius: "10px",
  textTransform: "uppercase",
  alignItems: "center",
  backgroundColor: props.backgroundColor ? props.backgroundColor : TakeTurnsColors.background,
  display: "flex",
  justifyContent: "center",
}));

const FolderCard__Content = styled(Box)({
  flex: 1,
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderTop: 0,
  borderBottom: 0,
  padding: "5px 10px",
});

const FolderCard__LastUpdateStatus = styled(Box)({
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderTop: 0,
  borderBottom: 0,
});

const FolderCard__FolderStats = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 1,
  height: 25,
  width: "100%",
  backgroundColor: "white",
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: "0 0 10px 10px",
  padding: "0 10px",
  fontSize: 9,
});
