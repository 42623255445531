/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { useFetchDestinationFolderRule } from "@taketurns-rules/collaboration/queries/folder/useFetchDestinationFolderRule";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { DestinationFolderList, DestinationFolderListProps } from "../../MoveDestination/DestinationFolderList";
import { DestinationFolderListItem } from "../../MoveDestination/DestinationFolderListItem";
import { MoveDestinationDialog, MoveDestinationDialogProps } from "../../MoveDestination/MoveDestinationDialog";

export const MoveDestinationDialogInCollaborationView = ({
  onSubmitDestination,
  open,
  onClose,
  excludedFolderId,
}: Omit<MoveDestinationDialogProps, "getFolder" | "DestinationFolderListSlot">) => {
  return (
    <MoveDestinationDialog
      open={open}
      onClose={onClose}
      excludedFolderId={excludedFolderId}
      DestinationFolderListSlot={DestinationFolderListInCollaborationView}
      onSubmitDestination={onSubmitDestination}
    />
  );
};

const DestinationFolderListInCollaborationView = ({
  onSelectedFolderChange,
  excludedFolderId,
}: Omit<DestinationFolderListProps, "getDestinationFolder" | "FolderListItem">) => {
  return (
    <DestinationFolderList
      onSelectedFolderChange={onSelectedFolderChange}
      excludedFolderId={excludedFolderId}
      FolderListItem={DestinationFolderListItemInCollaborationView}
      getDestinationFolder={useGetDestinationFolderRule}
    />
  );
};

const useGetDestinationFolderRule = (folderId: string) => {
  const { folder, loadingDestinationFolder, errorOnFetchingDestinationFolder } =
    useFetchDestinationFolderRule(folderId);
  const fetchLoadingManager = new FetchLoadingManager([loadingDestinationFolder], [errorOnFetchingDestinationFolder]);
  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }
  return {
    destinationFolder: folder && getDestinationFolderFromFolder(folder),
    loadingDestinationFolder: fetchLoadingManager.hasFetchLoading(),
    errorWhileFetchingDestinationFolder: fetchLoadingManager.hasFetchInError(),
  };
};

const getDestinationFolderFromFolder = (folder: Folder): DestinationFolder => {
  return {
    id: folder.id,
    name: folder.name,
    folders:
      folder.folders
        ?.filter((folder) => folder.status !== ContentStatus.Removed && folder.prevStatus !== ContentStatus.Removed)
        .map((folder) => getDestinationFolderFromFolder(folder)) ?? [],
  };
};

const DestinationFolderListItemInCollaborationView = ({
  destinationFolder,
  onFolderClick,
}: {
  destinationFolder: DestinationFolder;
  onFolderClick: () => void;
}) => {
  return <DestinationFolderListItem folderName={destinationFolder.name} onFolderClick={onFolderClick} />;
};
