/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, Fade, Stack, TextField, Typography } from "@mui/material";
import { KeyboardEvent, useState } from "react";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { getUserSettingsSectionUrl, PROFILE_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { TakeTurnsDefaultButton, TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { setLoggedInReturnPath } from "@taketurns-repositories/webapp/sessionStorage/loggedInReturnPath";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { validateEmail } from "@taketurns-rules/commons/util/form/formUtils";
import { useEmailFromLocationStateRule } from "@taketurns-rules/webapp/queries/useEmailFromLocationStateRule";
import { CodeInput } from "./CodeInput";

interface PasswordlessSignInFormProps {
  isInForgotPasswordProcess?: boolean;
}

export const PasswordlessSignInForm = ({ isInForgotPasswordProcess }: PasswordlessSignInFormProps) => {
  const {
    passwordLessProcess: {
      createAccountOrUseExistingAndSendAuthenticationCode,
      loginWithAuthenticationCode,
      error,
      loading,
    },
  } = useAuthContext();
  const [authenticationCodeSent, setAuthenticationCodeSent] = useState(false);
  const emailFromLocationState = useEmailFromLocationStateRule();
  const [email, setEmail] = useState(emailFromLocationState);
  const [resendCodeButtonDisabled, setResendCodeButtonDisabled] = useState(true);

  const { t } = useWebAppTranslations("passwordless-signin");
  const isOnMobile = useIsOnMobileRule();

  async function sendAuthenticationCodeAndPrepareRedirectionToProfileSectionAfterLogin() {
    try {
      setResendCodeButtonDisabled(true);
      await createAccountOrUseExistingAndSendAuthenticationCode(email.toLowerCase()).then(() => {
        setTimeout(() => setResendCodeButtonDisabled(false), 2000);
      });
      setAuthenticationCodeSent(true);
      if (isInForgotPasswordProcess) {
        setLoggedInReturnPath(getUserSettingsSectionUrl(PROFILE_SECTION));
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handlePressEnterKey(e: KeyboardEvent<HTMLFormElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <Stack component="form" noValidate onKeyDown={handlePressEnterKey} direction="column" spacing={2.5}>
      <Typography color={TakeTurnsColors.darkGray} variant={"body2"}>
        {t("enterEmail")}
      </Typography>
      <TextField
        data-cy="PasswordlessSignInForm.emailInput"
        value={email}
        placeholder={t("email")}
        autoComplete="username"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {!authenticationCodeSent && (
        <TakeTurnsDefaultButton
          data-cy="PasswordlessSignInForm.submitButton"
          disabled={loading || !validateEmail(email)}
          startIcon={loading ? <CircularProgress size={16} color={"secondary"} /> : undefined}
          onClick={sendAuthenticationCodeAndPrepareRedirectionToProfileSectionAfterLogin}
          color="secondary"
          fullWidth={isOnMobile}
          sx={{ maxWidth: { xs: "350px", md: "initial" }, alignSelf: { xs: "center", md: "flex-end" } }}
        >
          {authenticationCodeSent ? t("codeSent") : t("sendCodeButton")}
        </TakeTurnsDefaultButton>
      )}
      <Fade in={authenticationCodeSent}>
        <Stack direction="column" spacing={2.5} alignItems={"center"}>
          <Typography color={TakeTurnsColors.darkGray} variant={"body2"}>
            {t("enterVerificationCode.begin")} <b>{email}</b> {t("enterVerificationCode.end")}
          </Typography>
          {loading ? <CircularProgress /> : <CodeInput length={6} onComplete={loginWithAuthenticationCode} />}
          {error && <Typography color={"error"}>{t("error")}</Typography>}
          {!loading && (
            <Typography
              sx={{ display: "flex", alignItems: "baseline", gap: "4px" }}
              fontSize={12}
              color={TakeTurnsColors.darkGray}
            >
              {t("resendCode.didNotReceiveCode")}
              <TakeTurnsTextButton
                disabled={resendCodeButtonDisabled}
                variant={"text"}
                fontSize="12px"
                onClick={sendAuthenticationCodeAndPrepareRedirectionToProfileSectionAfterLogin}
              >
                {t("resendCode.resendCodeLink")}
              </TakeTurnsTextButton>
            </Typography>
          )}
        </Stack>
      </Fade>
    </Stack>
  );
};
