/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError, gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";

export function useFetchFilteredCollaborationList(
  appliedFilter: CollaborationFilterType,
  participantUserIds?: string[],
): {
  collaborations: Collaboration[];
  errorOnCollaborationList: ApolloError;
  loadingCollaborationList: boolean;
} {
  const query = COLLABORATION_LIST_QUERIES[appliedFilter];
  const {
    data,
    error: errorOnCollaborationList,
    loading: loadingCollaborationList,
  } = useQuery<{ [filter in CollaborationFilterType]: { items: Collaboration[] } }>(query, {
    nextFetchPolicy: "cache-and-network",
    pollInterval: 2000,
    variables: {
      participantUserIds,
    },
  });

  return {
    collaborations: data?.[appliedFilter]?.items ?? [],
    errorOnCollaborationList,
    loadingCollaborationList,
  };
}

const COMMON_COLLABORATION_ROW_FIELD_FRAGMENT = gql`
  fragment CommonCollaborationRowFields on Collaboration {
    id
    name
    userContext {
      id
      otherPartyName
      role
      isInvitee
      isParticipant
      isSupervisor
      isHidden
    }
    lastUpdateEvent {
      id
      eventType
      isFromConnectedUserParty
      date
      note
      partyName
      party
      userId
      user {
        id
        firstName
        lastName
        email
        avatar
        jobTitle
      }
    }
  }
`;

const ACTIVE_COLLABORATION_ROW_FIELD_FRAGMENT = gql`
  fragment ActiveCollaborationRowFields on Collaboration {
    ...CommonCollaborationRowFields
    status
    deadline
    contentMetadata {
      id
      attachmentToReviewCount
      requestToFulfillCount
      hasPendingUpdates
    }
  }
  ${COMMON_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

const GET_ACTIVE_COLLABORATIONS = gql`
  query activeCollaborations($limit: Int, $from: Int, $participantUserIds: [ID!]) {
    activeCollaborations(limit: $limit, from: $from, participantUserIds: $participantUserIds) {
      items {
        ...ActiveCollaborationRowFields
      }
    }
  }
  ${ACTIVE_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

const CLOSED_COLLABORATION_ROW_FIELD_FRAGMENT = gql`
  fragment ClosedCollaborationRowFields on Collaboration {
    ...CommonCollaborationRowFields
    status
    wipeDate
  }
  ${COMMON_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

const GET_ALL_COLLABORATIONS = gql`
  query allCollaborations($limit: Int, $from: Int, $participantUserIds: [ID!]) {
    allCollaborations(limit: $limit, from: $from, participantUserIds: $participantUserIds) {
      items {
        ...ActiveCollaborationRowFields
        ...ClosedCollaborationRowFields
      }
    }
  }
  ${ACTIVE_COLLABORATION_ROW_FIELD_FRAGMENT}
  ${CLOSED_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

const GET_CLOSED_COLLABORATIONS = gql`
  query closedCollaborations($limit: Int, $from: Int, $participantUserIds: [ID!]) {
    closedCollaborations(limit: $limit, from: $from, participantUserIds: $participantUserIds) {
      items {
        ...ClosedCollaborationRowFields
      }
    }
  }
  ${CLOSED_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

const GET_STARRED_COLLABORATIONS = gql`
  query starredCollaborations($limit: Int, $from: Int, $participantUserIds: [ID!]) {
    starredCollaborations(limit: $limit, from: $from, participantUserIds: $participantUserIds) {
      items {
        ...ActiveCollaborationRowFields
        ...ClosedCollaborationRowFields
      }
    }
  }
  ${ACTIVE_COLLABORATION_ROW_FIELD_FRAGMENT}
  ${CLOSED_COLLABORATION_ROW_FIELD_FRAGMENT}
`;

// this query is exported so that the mutation acceptInvitation can use it
export const GET_PENDING_INVITATIONS = gql`
  query pendingInvitations($limit: Int, $from: Int) {
    pendingInvitations(limit: $limit, from: $from) {
      items {
        id
        name
        otherPartyName
        invitingUser {
          email
          firstName
          lastName
          avatar
          isInInviteeParty
        }
      }
    }
  }
`;

const COLLABORATION_LIST_QUERIES = {
  [CollaborationFilterType.activeCollaborations]: GET_ACTIVE_COLLABORATIONS,
  [CollaborationFilterType.allCollaborations]: GET_ALL_COLLABORATIONS,
  [CollaborationFilterType.closedCollaborations]: GET_CLOSED_COLLABORATIONS,
  [CollaborationFilterType.pendingInvitations]: GET_PENDING_INVITATIONS,
  [CollaborationFilterType.starredCollaborations]: GET_STARRED_COLLABORATIONS,
};
