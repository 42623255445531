/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, DialogContent, Divider, Stack, styled, Tooltip, Typography } from "@mui/material";
import React, { Fragment, useEffect, useRef } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { AddAttachmentRevisionButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AddAttachmentRevisionButton";
import { AskToReviewAttachmentSwitch } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AskToReviewAttachmentSwitch";
import { AttachmentRevisionList } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionList/AttachmentRevisionList";
import { DocumentRevisionPreview } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionPreview";
import { RemoveAttachmentButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/RemoveAttachmentButton";
import { AttachmentDetailsHeaderDownloadSaveCancelButtons } from "@taketurns-components/collaboration/Shared/content/AttachmentDetailsHeaderDownloadSaveCancelButtons";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { DocumentDetails } from "@taketurns-components/document/DocumentView/DocumentDetails";
import { PdfViewerExposedFunction } from "@taketurns-components/document/FilePreview/PdfViewer/PdfViewer";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useFetchDocumentRevisions } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisions";
import { useRemoveAttachmentRevisionRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useRemoveAttachmentRevisionRule";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { useFetchAttachmentAvailableActionsRule } from "@taketurns-rules/collaboration/queries/attachment/useFetchAttachmentAvailableActionsRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { AttachmentAiAssistant } from "./AttachmentAiAssistant/AttachmentAiAssistant";

interface DesktopAttachmentDetailsDialogContentParams {
  isPdfUpdated: boolean;
  onPdfUpdatedChange: (updated: boolean) => void;
  onCloseDialogClicked: () => void;
}

export const DesktopAttachmentDetailsDialogContent = (props: DesktopAttachmentDetailsDialogContentParams) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const attachment = useGetOpenedAttachmentRule();

  const { loadingRemoveRevision } = useRemoveAttachmentRevisionRule();

  const { canEditDocumentDetails } = useFetchAttachmentAvailableActionsRule(attachment);

  const { selectRevision, selectedRevisionId } = useSelectDocumentRevisionRule();

  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();

  const pdfViewerComponentRef = useRef<PdfViewerExposedFunction>(null);

  const saveRevision = async () => {
    await pdfViewerComponentRef.current.uploadPdfRevision();
  };

  const cancelAnnotations = () => {
    pdfViewerComponentRef.current.cancelPendingAnnotations();
  };

  useEffect(() => {
    if (!isAttachmentRevisionAddInProgress && !selectedRevisionId) {
      selectRevision(attachment.revisionId);
    }
  }, [attachment.revisionId, isAttachmentRevisionAddInProgress, selectRevision, selectedRevisionId]);

  if (!selectedRevisionId) {
    return null;
  }

  return (
    <Fragment>
      <DialogContent sx={{ padding: 0, display: "flex" }}>
        <AttachmentDetails__Content>
          <AttachmentDetailsHeader__Container>
            <DocumentDetails documentId={attachment.id} canEdit={canEditDocumentDetails} />
            <Stack direction={"row"} spacing={1} alignItems={"end"}>
              <AttachmentAiAssistant
                documentId={attachment.id}
                collaborationId={collaborationId}
                revisionId={selectedRevisionId}
              />
              <AttachmentDetailsHeaderDownloadSaveCancelButtons
                documentId={attachment.id}
                collaborationId={collaborationId}
                revisionId={selectedRevisionId}
                onSaveRevisionButtonClick={saveRevision}
                onCancelAnnotationsButtonClick={cancelAnnotations}
                isPdfUpdated={props.isPdfUpdated}
              />
            </Stack>
          </AttachmentDetailsHeader__Container>
          <Divider />
          <Box
            className="DesktopAttachmentDetailsDialogContent__DocumentRevisionPreviewContainer"
            flex={1}
            sx={{ borderRight: `1px solid ${TakeTurnsColors.borderColor}`, minHeight: 0 }}
          >
            <DocumentRevisionPreview
              ref={pdfViewerComponentRef}
              collaborationId={collaborationId}
              documentId={attachment.id}
              revisionId={selectedRevisionId}
              loadingRemoveRevision={loadingRemoveRevision}
              canEditRevision={canEditDocumentDetails}
              onPdfUpdatedChange={props.onPdfUpdatedChange}
            />
          </Box>
        </AttachmentDetails__Content>
        <SideBar attachment={attachment} />
      </DialogContent>
      <Divider />
      <TakeTurnsDialogFooter closeDialog={props.onCloseDialogClicked}></TakeTurnsDialogFooter>
    </Fragment>
  );
};

const RemoveAttachmentButtonWithTooltip = ({
  deleteAttachmentDisabled,
  attachment,
  title,
}: {
  title: string;
  deleteAttachmentDisabled: boolean;
  attachment: Attachment;
}) => (
  <Tooltip
    title={title}
    placement="bottom-end"
    disableFocusListener={!deleteAttachmentDisabled}
    disableHoverListener={!deleteAttachmentDisabled}
    disableTouchListener={!deleteAttachmentDisabled}
  >
    <span>
      <RemoveAttachmentButton attachment={attachment} disabled={deleteAttachmentDisabled} />
    </span>
  </Tooltip>
);

const SideBar = ({ attachment }: { attachment: Attachment }) => {
  const { t } = useAttachmentDetailsTranslation();
  const { canEditDocumentDetails, canDelete, canUploadRevision, canMarkForReview, isDeleteAttachmentDisabled } =
    useFetchAttachmentAvailableActionsRule(attachment);
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { revisions, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } = useFetchDocumentRevisions(
    collaborationId,
    attachment.id,
  );
  return (
    <Stack
      flex={"0 0 320px"}
      direction={"column"}
      minWidth={0}
      sx={{ borderLeft: `1px solid ${TakeTurnsColors.borderColor}` }}
    >
      <Stack padding={3} gap={2}>
        <Typography>{t("revision.versionHistory")}</Typography>
        {canUploadRevision && !attachment.hasAttachmentPendingRevision && <AddAttachmentRevisionButton />}
      </Stack>
      <AttachmentRevisionList
        attachment={attachment}
        canEdit={canEditDocumentDetails}
        revisions={revisions}
        loadingDocumentRevisions={loadingDocumentRevisions}
        errorOnFetchingDocumentRevisions={errorOnFetchingDocumentRevisions}
      />
      {canMarkForReview && (
        <Fragment>
          <Divider /> <AskToReviewAttachmentSwitch attachment={attachment} />
        </Fragment>
      )}
      {canDelete && (
        <Fragment>
          <Divider />
          <Box>
            <RemoveAttachmentButtonWithTooltip
              title={t("cannotDeleteLockedAttachment")}
              deleteAttachmentDisabled={isDeleteAttachmentDisabled}
              attachment={attachment}
            />
          </Box>
        </Fragment>
      )}
    </Stack>
  );
};

const AttachmentDetailsHeader__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 16,
  alignItems: "center",
  justifyContent: "space-between",
  padding: 24,
});

const AttachmentDetails__Content = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
});
