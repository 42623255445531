/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { forwardRef, Fragment } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { DocumentExtension } from "@taketurns-components/document/DocumentView/DocumentExtension";
import { ListItemTitle } from "../ListItemTitle";
import { AttachmentMarkersInList } from "./AttachmentMarkersInList";

interface MobileAttachmentListItemProps {
  attachment: Attachment;
  isAttachmentRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  openAttachmentDetails: () => void;
  color: string;
  backgroundColor: string;
}

export const MobileAttachmentListItem = forwardRef<HTMLLIElement | null, MobileAttachmentListItemProps>(
  (props: MobileAttachmentListItemProps, ref) => (
    <MobileListItem
      dataCy={`MobileAttachmentListItem.${props.attachment.id}`}
      sx={{ backgroundColor: props.backgroundColor }}
      ref={ref}
      titleSlot={<ListItemTitle title={props.attachment.document.name} shouldStrikeTitle={props.isAttachmentRemoved} />}
      subtitleSlot={
        <Fragment>
          <DocumentExtension extension={props.attachment.document.extension} size={props.attachment.document.size} />
          <AttachmentMarkersInList attachment={props.attachment} />
          {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.attachment.prevStatus} />}
        </Fragment>
      }
      rightEndSlot={props.attachment.toReview && <ReviewListItemChip />}
      onClick={props.isAttachmentRemoved ? undefined : props.openAttachmentDetails}
    />
  ),
);

MobileAttachmentListItem.displayName = "MobileAttachmentListItem";
