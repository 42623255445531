/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, Stack, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { dataUrlToFile } from "@taketurns-rules/commons/util/file/fileUtils";
import { useUpdateAvatarRule } from "@taketurns-rules/user/commands/useUpdateAvatarRule";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { DialogFooter } from "../../commons/dialog/DialogFooter";
import { ResponsiveDialog } from "../../commons/dialog/ResponsiveDialog";
import "./cropper.css";
import "./UserPictureEdit_CropperOverride.css";

interface UserPictureEditProps {
  connectedUser: ConnectedUser;
}

/**
 * Display user avatar edit dialog if
 * user wants to change his picture
 * @param {UserPictureEditProps} props
 * @returns {ReactNode} Picture+edition component
 */
export const UserPictureEdit = (props: UserPictureEditProps) => {
  const { connectedUser } = props;
  const { t } = useUserSharedTranslation();

  const [edit, setEdit] = useState<boolean>(false);
  const avatarFileInputRef = useRef<HTMLInputElement>(null);
  const [imageToCrop, setImageToCrop] = useState<string>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const closeEdit = () => {
    setEdit(false);
  };

  function chooseAvatarFile(file: File): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageToCrop(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  }

  const { saveAvatar, isProcessingUpload } = useUpdateAvatarRule(connectedUser);
  const modifyPictureUrl = async () => {
    if (cropperRef?.current?.cropper) {
      const fileToSave = await dataUrlToFile(
        cropperRef.current.cropper.getCroppedCanvas().toDataURL(),
        `${connectedUser.id}.jpeg`,
        `image/jpeg`,
      );
      await saveAvatar(fileToSave);
      setEdit(false);
    }
  };

  const UserPictureEditDialog = () => (
    <ResponsiveDialog
      fullWidth
      open={edit}
      title={t("UserSettings.changePicture")}
      content={
        <Stack
          sx={{
            gap: 1,
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Stack direction="row">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="file-input"
              type="file"
              ref={avatarFileInputRef}
              value=""
              onChange={(e) => chooseAvatarFile(e.currentTarget.files[0])}
            />
            <TakeTurnsDefaultButton onClick={() => avatarFileInputRef.current.click()}>
              {t("UserSettings.chooseAvatarButton")}
            </TakeTurnsDefaultButton>
          </Stack>
          {imageToCrop && (
            <Cropper
              src={imageToCrop}
              style={{ height: 400, width: 400, borderRadius: "50%" }}
              aspectRatio={1}
              viewMode={2}
              ref={cropperRef}
              dragMode={"move"}
            ></Cropper>
          )}
        </Stack>
      }
      footer={
        <DialogFooter hasCancelAction onClose={closeEdit}>
          <TakeTurnsDefaultButton disabled={isProcessingUpload} onClick={modifyPictureUrl}>
            {t("UserSettings.submit")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );

  return (
    <Fragment>
      <Stack alignItems={"center"}>
        <Avatar sx={{ width: "100px", height: "100px", margin: "auto" }} src={connectedUser.avatar} />
        <Typography
          variant="caption"
          color={TakeTurnsColors.darkGray}
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => setEdit(true)}
        >
          {t("UserSettings.changePicture")}
        </Typography>
      </Stack>
      <UserPictureEditDialog />
    </Fragment>
  );
};
