/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { AttachmentsCounter } from "@taketurns-components/collaboration/Shared/content/AttachmentsCounter";
import { RequestsCounter } from "@taketurns-components/collaboration/Shared/content/RequestsCounter";
import { FolderInCreation } from "@taketurns-repositories/collaboration/models/folderInCreation";
import { useGetFolderInCreationRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useGetFolderInCreationRule";

interface FolderStatsProps {
  folder: FolderInCreation;
}

export const FolderStatsInCreation = ({ folder }: FolderStatsProps) => {
  const getFolderInCreation = useGetFolderInCreationRule();

  const attachmentsCount = countAttachmentsInFolder(folder, getFolderInCreation);
  const requestsCount = countRequestsInFolder(folder, getFolderInCreation);

  return (
    <Stack direction="column">
      <AttachmentsCounter attachmentsCount={attachmentsCount} />
      {requestsCount > 0 && <RequestsCounter requestsCount={requestsCount} />}
    </Stack>
  );
};

const countAttachmentsInFolder = (
  folder: FolderInCreation,
  getFolderInCreation: (folderId: string) => FolderInCreation,
) => {
  let attachmentsCount = folder.attachments.length;
  for (const subFolderId of folder.folders) {
    const subFolder = getFolderInCreation(subFolderId);
    attachmentsCount += countAttachmentsInFolder(subFolder, getFolderInCreation);
  }
  return attachmentsCount;
};

const countRequestsInFolder = (
  folder: FolderInCreation,
  getFolderInCreation: (folderId: string) => FolderInCreation,
) => {
  let requestsCount = folder.requests.length;
  for (const subFolderId of folder.folders) {
    const subFolder = getFolderInCreation(subFolderId);
    requestsCount += countRequestsInFolder(subFolder, getFolderInCreation);
  }
  return requestsCount;
};
