/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { FolderInCreation } from "@taketurns-repositories/collaboration/models/folderInCreation";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { useGetFolderInCreationRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useGetFolderInCreationRule";
import { DestinationFolderList, DestinationFolderListProps } from "../../MoveDestination/DestinationFolderList";
import { DestinationFolderListItem } from "../../MoveDestination/DestinationFolderListItem";
import { MoveDestinationDialog, MoveDestinationDialogProps } from "../../MoveDestination/MoveDestinationDialog";

export const MoveDestinationDialogInCreation = ({
  onSubmitDestination,
  open,
  onClose,
  excludedFolderId,
}: Omit<MoveDestinationDialogProps, "DestinationFolderListSlot">) => {
  return (
    <MoveDestinationDialog
      open={open}
      onClose={onClose}
      excludedFolderId={excludedFolderId}
      DestinationFolderListSlot={DestinationFolderListInCreation}
      onSubmitDestination={onSubmitDestination}
    />
  );
};

const DestinationFolderListInCreation = ({
  onSelectedFolderChange,
  excludedFolderId,
}: Omit<DestinationFolderListProps, "getDestinationFolder" | "FolderListItem">) => {
  const getFolder = useGetFolderInCreationForMoveRule();
  return (
    <DestinationFolderList
      onSelectedFolderChange={onSelectedFolderChange}
      excludedFolderId={excludedFolderId}
      getDestinationFolder={getFolder}
      FolderListItem={DestinationFolderListItemInCreation}
    />
  );
};

const useGetFolderInCreationForMoveRule = () => {
  const getFolderInCreationRule = useGetFolderInCreationRule();
  return (
    folderId: string,
  ): {
    destinationFolder: DestinationFolder;
    loadingDestinationFolder: boolean;
    errorWhileFetchingDestinationFolder: boolean;
  } => {
    const folderInCreation = getFolderInCreationRule(folderId);
    return {
      destinationFolder: getDestinationFolderFromFolderInCreationId(folderId, folderInCreation),
      loadingDestinationFolder: false,
      errorWhileFetchingDestinationFolder: undefined,
    };
  };
};

const getDestinationFolderFromFolderInCreationId = (
  folderId: string,
  folderInCreation: FolderInCreation,
): DestinationFolder => {
  return {
    id: folderId,
    name: folderInCreation.name,
    folders: folderInCreation.folders.map((folderId): DestinationFolder => {
      return {
        id: folderId,
        name: folderId,
        folders: [],
      };
    }),
  };
};

const DestinationFolderListItemInCreation = ({
  destinationFolder,
  onFolderClick,
}: {
  destinationFolder: DestinationFolder;
  onFolderClick: () => void;
}) => {
  const folderName = useGetFolderInCreationRule()(destinationFolder.id).name;
  return <DestinationFolderListItem folderName={folderName} onFolderClick={onFolderClick} />;
};
