/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconFolder } from "../../commons/icons";

export const DestinationFolderListItem = ({
  folderName,
  onFolderClick,
}: {
  folderName: string;
  onFolderClick: () => void;
}) => {
  return (
    <TakeTurnsListItem
      titleSlot={
        <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
          <IconFolder size={16} color={TakeTurnsColors.grayButton} />
          <Typography variant="body2" noWrap>
            {folderName}
          </Typography>
        </Stack>
      }
      onClick={onFolderClick}
    />
  );
};
