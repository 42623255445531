/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { useGetUploadIdsByCollaborationId } from "@taketurns-repositories/collaboration/state/read/collaborationView/getUploadIdsByCollaborationId";
import { useAttachFilesInCurrentFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useAttachFilesInCurrentFolderRule";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { DesktopContentFooterMenu } from "./DesktopContentFooterMenu";
import { MobileContentFooterMenu } from "./MobileContentFooterMenu";

export const ContentFooterMenuProvider = ({
  editable,
  displayContentAsCard,
  onToggleViewModeClick,
  isEmptyContent,
}: {
  editable: boolean;
  displayContentAsCard: boolean;
  onToggleViewModeClick: () => unknown;
  isEmptyContent?: boolean;
}) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const uploadIds = useGetUploadIdsByCollaborationId(collaborationId);

  const canUserRoleAddFiles = useCollaborationPermissions(CollaborationCommand.ADD_FILES);
  const canUserAddFiles = editable && canUserRoleAddFiles;
  const attachFilesRule = useAttachFilesInCurrentFolderRule();
  const attachFiles = async (files: FileList) => {
    await attachFilesRule(Array.from(files));
  };

  const canUserRoleAddFolders = useCollaborationPermissions(CollaborationCommand.CREATE_FOLDER);
  const canUserAddFolders = editable && canUserRoleAddFolders;
  const { addFolder } = useAddFolderRule();

  const canUserRoleAddRequests = useCollaborationPermissions(CollaborationCommand.REQUEST_FILES);
  const canUserAddRequests = editable && canUserRoleAddRequests;
  const { addRequest } = useAddRequestRule();

  const isOnMobile = useIsOnMobileRule();

  return (
    <ContentFooterMenuContainer>
      {isOnMobile ? (
        <MobileContentFooterMenu
          canUserAddFiles={canUserAddFiles}
          canUserAddFolders={canUserAddFolders}
          canUserAddRequests={canUserAddRequests}
          isEmptyContent={isEmptyContent}
          uploadIds={uploadIds}
          attachFiles={attachFiles}
          addFolder={addFolder}
          addRequest={addRequest}
        />
      ) : (
        <DesktopContentFooterMenu
          canUserAddFiles={canUserAddFiles}
          canUserAddFolders={canUserAddFolders}
          canUserAddRequests={canUserAddRequests}
          displayContentAsCard={displayContentAsCard}
          onToggleViewModeClick={onToggleViewModeClick}
          isEmptyContent={isEmptyContent}
          uploadIds={uploadIds}
          attachFiles={attachFiles}
          addFolder={addFolder}
          addRequest={addRequest}
        />
      )}
    </ContentFooterMenuContainer>
  );
};

const ContentFooterMenuContainer = styled(Box)({
  position: "absolute",
  right: 0,
  bottom: "env(safe-area-inset-bottom)",
  left: 0,
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
  padding: "0px 24px",
  height: "50px",
});
