/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, styled, Typography } from "@mui/material";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { IconReview, IconSquare } from "@taketurns-components/commons/icons";
import { TakeTurnsSwitch } from "@taketurns-components/commons/switch/TakeTurnsSwitch";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useMarkAttachmentForReviewRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useMarkAttachmentForReviewRule";
import { useUnmarkAttachmentForReviewRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useUnmarkAttachmentForReviewRule";
import { useFetchOtherPartyNameRule } from "@taketurns-rules/collaboration/queries/context/useFetchOtherPartyNameRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const AskToReviewAttachmentSwitch = ({ attachment }: { attachment: Attachment }) => {
  const { markAttachmentForReview } = useMarkAttachmentForReviewRule(attachment.id);
  const { unmarkAttachmentForReview } = useUnmarkAttachmentForReviewRule(attachment.id);

  const { otherPartyName } = useFetchOtherPartyNameRule();

  const { t } = useAttachmentDetailsTranslation();
  const isOnMobile = useIsOnMobileRule();

  const markOrUnmarkForReview = (markForReview: boolean) => {
    if (markForReview) {
      markAttachmentForReview();
    } else {
      unmarkAttachmentForReview();
    }
  };

  if (isOnMobile) {
    return (
      <Button
        variant="text"
        startIcon={attachment.markedForReview ? <IconReview /> : <IconSquare />}
        sx={attachment.markedForReview ? { color: TakeTurnsColors.darkBlue } : undefined}
        onClick={() => {
          markOrUnmarkForReview(!attachment.markedForReview);
        }}
      >
        {t("askToReview")}
      </Button>
    );
  }

  return (
    <AskToReviewAttachmentSwitch__DesktopContainer>
      <Typography
        variant={"body2"}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >{`${t("markForReview")} ${otherPartyName}`}</Typography>
      <TakeTurnsSwitch
        checked={attachment.markedForReview}
        onChange={(event) => {
          markOrUnmarkForReview(event.target.checked);
        }}
        borderColor="white"
      />
    </AskToReviewAttachmentSwitch__DesktopContainer>
  );
};

const AskToReviewAttachmentSwitch__DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  backgroundColor: TakeTurnsColors.darkBlue,
  padding: theme.spacing(1, 3),
  color: TakeTurnsColors.white,
}));
