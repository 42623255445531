/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, Fragment } from "react";
import { ContentStatus, ContentStatus as ContentStatusEnum, Folder } from "@taketurns/taketurns-graphql-repository";
import { FolderMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/FolderMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { EditFolderNameDialog } from "@taketurns-components/collaboration/Shared/content/EditFolderDialog";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useRenameFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useRenameFolderRule";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetSubFolderRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetSubFolderRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useGetFolderContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/folder/useGetFolderContextMenuConfigurationRule";

import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToFolderChangesRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToFolderChangesRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { DesktopFolderListItem } from "./DesktopFolderListItem";
import { MobileFolderListItem } from "./MobileFolderListItem";

interface FolderListItemProps {
  folderId: string;
  editable: boolean;
  dropZoneConfig: FilesDragAndDropWithOverlayConfig;
}

export const FolderListItem = ({ folderId, editable, dropZoneConfig }: FolderListItemProps) => {
  const folder = useGetSubFolderRule(folderId);
  useSubscribeToFolderChangesRule(folderId);
  const { renameFolder } = useRenameFolderRule(folder?.id);
  useSubscribeToFolderChangesRule(folderId);
  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();

  const folderContextMenuConfiguration = useGetFolderContextMenuConfigurationRule(
    folder,
    openEditDialog,
    openMoveDestinationDialog,
  );

  let listItem = <ListItem folderId={folderId} editable={editable} dropZoneConfig={dropZoneConfig} />;
  if (editable && !hasFolderBeenRemoved(folder)) {
    const ListItemWithContextMenu = WithContextMenu<FolderListItemProps>(ListItem, folderContextMenuConfiguration);
    listItem = (
      <Fragment>
        <ListItemWithContextMenu folderId={folderId} editable={editable} dropZoneConfig={dropZoneConfig} />
        <EditFolderNameDialog
          open={isEditDialogOpened}
          onClose={closeEditDialog}
          folderName={folder.name}
          renameFolder={renameFolder}
        />
        <FolderMoveDestinationDialogInCollaborationView
          folder={folder}
          open={isMoveDestinationDialogOpen}
          onClose={closeMoveDestinationDialog}
        />
      </Fragment>
    );
  }

  return listItem;
};

const hasFolderBeenRemoved = (folder: Folder) =>
  folder.status === ContentStatusEnum.Removed || folder.prevStatus === ContentStatusEnum.Removed;
const hasFolderBeenRemovedInPreviousTurn = (folder: Folder) => folder.prevStatus === ContentStatusEnum.Removed;

const ListItem = forwardRef(
  ({ folderId, editable, dropZoneConfig }: FolderListItemProps, ref: ForwardedRef<HTMLLIElement | null>) => {
    const folder = useGetSubFolderRule(folderId);
    const isOnMobile = useIsOnMobileRule();

    const { moveIntoFolder } = useBrowseContentRule();

    const moveIntoListItemFolder = () => {
      moveIntoFolder(folder.id);
    };

    const isFolderRemoved = hasFolderBeenRemoved(folder);
    const { isCollaborationClosed } = useIsCollaborationClosedRule();
    const shouldDisplayPendingUpdatesMarker =
      !isCollaborationClosed && (folder.status !== ContentStatus.Unchanged || !!folder.updateCount);
    const shouldDisplayLastUpdatesMarker =
      !isCollaborationClosed &&
      ((folder.prevStatus && folder.prevStatus !== ContentStatus.Unchanged) || !!folder.prevUpdateCount);
    const lastUpdateStatus = !!folder.prevUpdateCount ? ContentStatus.Updated : folder.prevStatus;

    if (!folder) {
      return null;
    }

    const ItemComponent = isOnMobile ? MobileFolderListItem : DesktopFolderListItem;
    const item = (
      <ItemComponent
        backgroundColor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : "transparent"}
        folder={folder}
        isFolderRemoved={isFolderRemoved}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        lastUpdateStatus={lastUpdateStatus}
        shouldDisplayUpdatesMarker={shouldDisplayPendingUpdatesMarker}
        shouldDisplayReviewChip={folder.attachmentToReviewCount > 0}
        hasFolderBeenRemovedInPreviousTurn={hasFolderBeenRemovedInPreviousTurn(folder)}
        moveIntoListItemFolder={moveIntoListItemFolder}
        ref={ref}
      />
    );

    if (!editable || isFolderRemoved) {
      return item;
    }

    return (
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        {item}
      </FilesDragAndDropWithOverlay>
    );
  },
);
ListItem.displayName = "FolderListItem";
