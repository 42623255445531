/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Typography } from "@mui/material";
import { Fragment, MouseEvent, PointerEvent, useState } from "react";
import { ContextMenuConfiguration } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuConfiguration";
import { ContextMenuItem } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuItem";
import { IconMoreVertical } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

interface ContextMenuOnButtonProps {
  contextMenuConfiguration: ContextMenuConfiguration;
}

export const ContextMenuOnButton = ({ contextMenuConfiguration }: ContextMenuOnButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useSharedCollaborationTranslation();

  function handleOpen(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  function handleClickOnItem(itemAction: ContextMenuItem["action"]) {
    return (event: PointerEvent<HTMLLIElement>) => {
      event.stopPropagation();
      setTimeout(itemAction, 300);
      handleClose(event);
    };
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={handleOpen}>
        <IconMoreVertical />
      </IconButton>
      <MenuList open={open} anchorEl={anchorEl} onClose={handleClose} elevation={2}>
        {contextMenuConfiguration.contextMenuItems.map((item, index) => (
          <MenuItem key={index} onClick={(e) => e.stopPropagation()} onPointerDown={handleClickOnItem(item.action)}>
            <ContextMenuIcon>{item.icon}</ContextMenuIcon>
            <ListItemText>
              <Typography variant={"body2"}>{t(item.labelKey)}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Fragment>
  );
};

const MenuList = styled(Menu)({
  "& .MuiMenu-list": {
    padding: 0,
  },
});

const ContextMenuIcon = styled(ListItemIcon)({
  marginRight: 10,
  minWidth: "0 !important",
});
