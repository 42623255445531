/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";

export const IS_PASSWORD_ALREADY_SET = gql`
  query isConnectedUserPasswordAlreadySet {
    isConnectedUserPasswordAlreadySet
  }
`;
export const useFetchIsConnectedUserPasswordAlreadySet = () => {
  const {
    data,
    error: errorOnIsConnectedUserPasswordAlreadySet,
    loading: loadingIsConnectedUserPasswordAlreadySet,
  } = useQuery<{
    isConnectedUserPasswordAlreadySet: boolean;
  }>(IS_PASSWORD_ALREADY_SET);
  return {
    isConnectedPasswordAlreadySet: data?.isConnectedUserPasswordAlreadySet ?? false,
    loadingIsConnectedUserPasswordAlreadySet,
    errorOnIsConnectedUserPasswordAlreadySet,
  };
};
