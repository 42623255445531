/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, CircularProgress } from "@mui/material";
import { CancelAnnotationsButton } from "@taketurns-components/collaboration/Shared/content/CancelAnnotationsButton";
import { DownloadRevisionButton } from "@taketurns-components/collaboration/Shared/content/DownloadRevisionButton";
import { SaveRevisionButton } from "@taketurns-components/collaboration/Shared/content/SaveRevisionButton";
import { TakeTurnsGreyButton } from "@taketurns-components/commons/button";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";

interface AttachmentDetailsHeaderDownloadSaveCancelButtonsProps {
  collaborationId: string;
  documentId: string;
  revisionId: string;
  isPdfUpdated: boolean;
  onSaveRevisionButtonClick: () => void;
  onCancelAnnotationsButtonClick: () => void;
}

export const AttachmentDetailsHeaderDownloadSaveCancelButtons = (
  props: AttachmentDetailsHeaderDownloadSaveCancelButtonsProps,
) => {
  const { t } = useAttachmentDetailsTranslation();
  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  if (!props.isPdfUpdated && !isAttachmentRevisionAddInProgress) {
    return (
      <Box>
        <DownloadRevisionButton
          collaborationId={props.collaborationId}
          documentId={props.documentId}
          revisionId={props.revisionId}
        />
      </Box>
    );
  } else if (isAttachmentRevisionAddInProgress) {
    return (
      <Box>
        <TakeTurnsGreyButton
          disabled={true}
          startIcon={<CircularProgress color={"secondary"} size={20} variant={"indeterminate"} />}
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("loadingRevision")}
        </TakeTurnsGreyButton>
      </Box>
    );
  }
  return (
    <Box>
      <CancelAnnotationsButton onClick={props.onCancelAnnotationsButtonClick} />
      <SaveRevisionButton onClick={props.onSaveRevisionButtonClick} />
    </Box>
  );
};
